import React from 'react';
import {Form, Input, Modal} from "antd";

interface ComponentProps {
    isModalEditPasswordVisible: boolean,
    setIsModalEditPasswordVisible: (arg: boolean) => void,

}

const EditProfilePasswordModal = ({isModalEditPasswordVisible, setIsModalEditPasswordVisible}: ComponentProps) => {
    const [form] = Form.useForm()

    const handleSubmitForm = () => {
        form.validateFields().then(values => {
            console.log(values)
        }).catch(err => console.log(err))
    }
    return (
        <Modal title="Изменение пароля"
               visible={isModalEditPasswordVisible}
               onOk={() => setIsModalEditPasswordVisible(false)}
               onCancel={() => setIsModalEditPasswordVisible(false)}
               okText="Изменить"
               cancelText="Закрыть"
        >
            <Form name="complex-form" form={form} labelCol={{span: 8}} wrapperCol={{span: 16}}>
                <Form.Item label={'Старый пароль'}>
                    <Form.Item
                        name="oldPassword"
                        noStyle
                        rules={[{required: true, message: 'Пожайлуста введите ваш старый пароль'}]}
                    >
                        <Input.Password style={{width: 160}} name={'oldPassword'} placeholder="Старый пароль"/>
                    </Form.Item>
                </Form.Item>
                <Form.Item label={'Новый пароль'}>
                    <Form.Item
                        name="newPassword"
                        noStyle
                        rules={[{required: true, message: 'Пожайлуста введите ваш новый пароль'}]}
                    >
                        <Input.Password style={{width: 160}} name={'newPassword'} placeholder="Новый пароль"/>
                    </Form.Item>
                </Form.Item>
            </Form>

        </Modal>
    );
};

export default EditProfilePasswordModal;