import { Button, Col, Form, Input, message, Modal, Row } from "antd"
import { FC, useEffect, useState } from "react"
import { restApi } from "../../utils/variables";


interface ComponentProps {
    open: boolean,
    onClose: () => void,
    data?: { id: number, title: string } | null,
}

const TransportCompanyModal: FC<ComponentProps> = ({ open, onClose, data }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (data) {
            form.setFieldsValue({ title: data.title });
        } else {
            form.resetFields();
        }
    }, [data])

    const doAction = async (body: { title: string }, method: 'PATCH' | 'POST', id?: number) => {
        try {
            setLoading(true);
            const url = id && method === 'PATCH' ? `${restApi}/sp/delivery_companies/${id}/` : `${restApi}/sp/delivery_companies/`;
            const response = await fetch(url, {
                method,
                headers: {
                    Authorization: `Token ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
            if (response.ok) {
                message.success('Операция прошла успешно!')
                onClose();
            } else {
                throw new Error(`Ошибка запроса: ${response.statusText}`)
            }
        } catch (e: any) {
            message.error(`Ошибка ${id ? 'редактирования' : 'добавления'}! ${e.message}`);
            console.log('add/edit err', e);
        } finally {
            setLoading(false);
        }
    }

    const submit = (values: any) => {
        if (data) {
            doAction({ title: values.title }, 'PATCH', data.id);
        } else {
            doAction({ title: values.title }, 'POST');
        }
    }

    return (
        <Modal
            title={data ? 'Редактирование' : 'Добавление'}
            open={open}
            onCancel={onClose}
            footer={[]}
        >
            <Form form={form} layout="vertical" onFinish={submit}>
                <Row gutter={16}>
                    <Col lg={24} md={24} xs={24}>
                        <Form.Item
                            name="title"
                            label="Название"
                            rules={[{
                                required: true,
                                message: 'Пожалуйста, введите название ТК'
                            }]}
                        >
                            <Input placeholder="Введите название" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'end'}>
                    <Button
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                    >
                        {data ? 'Сохранить' : 'Добавить'}
                    </Button>
                </Row>
            </Form>
        </Modal>
    )
}

export default TransportCompanyModal;