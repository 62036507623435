import { EyeOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, List } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React, { FC } from "react";

interface ComponentProps {
  loadingUsers: boolean;
  users: any[];
  handleOpenModal?: (user: any) => void;
}

const AnalyticsAuthUserList: FC<ComponentProps> = ({
  loadingUsers,
  users,
  handleOpenModal,
}) => {
  return (
    <Card>
      <List
        itemLayout="horizontal"
        className="analytics"
        dataSource={users}
        loading={loadingUsers}
        renderItem={(item: any) => (
          <List.Item
            className="analytics-user"
            onDoubleClick={() => handleOpenModal?.(item)}
            actions={[
              <Button
                onClick={() => handleOpenModal?.(item)}
                type="primary"
                icon={<EyeOutlined />}
                size={"small"}
              />,
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar icon={<UserOutlined />} />}
              title={item.user_email}
              description={item.user_name}
            />
          </List.Item>
        )}
      />
    </Card>
  );
};

export default AnalyticsAuthUserList;
