import React, { FC, useEffect, useRef, useState } from "react";
import { message } from "antd";
import ChatComponent from "./ChatComponent";
import { observer } from "mobx-react-lite";

const Chat: FC<any> = () => {
  let timeout = 250;
  const [ws, setWs] = useState<any>(null);
  const [dialogs, setDialogs] = useState([]);
  const [fetchingDialogs, setFetchingDialogs] = useState(false);
  const [loadingDialogs, setLoadingDialogs] = useState(false);
  const [messages, setMessages] = useState<any>([]);
  const [countUnreadMessages, setCountUnreadMessages] = useState(0);
  const activeDialog = useRef(null);
  const [newMessage, setNewMessage] = useState(false);

  const [dialogLimit, setDialogLimit] = useState(10);
  const [messagesLimit, setMessageLimit] = useState(10);
  const [totalDialog, setTotalDialog] = useState(0);
  const [selectedDialogData, setSelectedDialogData] = useState(null);

  const isAnonDialogs = useRef(false);

  const setActiveDialog = (id: any) => {
    activeDialog.current = id;
  };

  const setIsAnonDialogs = (value: boolean) => {
    isAnonDialogs.current = value;
  };

  useEffect(() => {
    if (dialogLimit) {
      if (ws && ws.readyState) {
        ws.send(
          JSON.stringify({
            type: "dialogs",
            user: "admin",
            limit: dialogLimit,
            anon_chats: isAnonDialogs.current,
          })
        );
      }
    }
  }, [dialogLimit]);

  useEffect(() => {
    if (messagesLimit) {
      if (ws && ws.readyState && activeDialog.current) {
        ws.send(
          JSON.stringify({
            type: "get",
            dialog: activeDialog.current,
            limit: messagesLimit,
          })
        );
      }
    }
  }, [messagesLimit]);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      testConnect(localStorage.getItem("token"));
    }
  }, []);

  const handleChangeDialogLimit = (dialogLimit: any) => {
    dialogLimit && setDialogLimit(dialogLimit);
    setMessageLimit(20);
  };

  const handleChangeMessagesLimit = (messagesLimit: any) => {
    messagesLimit && setMessageLimit(messagesLimit);
  };

  const getMessages = async (id: any, data: any) => {
    setActiveDialog(null);
    data && setSelectedDialogData(data);
    if (ws && ws.readyState) {
      await ws.send(
        JSON.stringify({ type: "get", dialog: id, limit: messagesLimit })
      );
      activeDialog.current = id;
    }
  };

  const testConnect = (token: any) => {
    let webSocketChannel: any;
    if (token && token !== undefined) {
      webSocketChannel = new WebSocket(
        `wss://partobo.ru:8000/ws/chat/?authorization=${token}`
      );
    }

    let connectInterval: any;

    const openHandler = (e: any) => {
      console.log("Соединение с вебсокетом установлено ");
      setWs(webSocketChannel);
      timeout = 250;
      setLoadingDialogs(true);
      webSocketChannel.send(
        JSON.stringify({
          type: "dialogs",
          user: "admin",
          limit: dialogLimit,
        })
      );
      clearTimeout(connectInterval);
    };

    const closeHandler = (event: any) => {
      timeout += timeout;
      connectInterval = setTimeout(testConnect, Math.min(10000, timeout));

      console.log(
        `Вебсокет недоступен. Восстановление соединения через ${Math.min(
          10000 / 1000,
          (timeout + timeout) / 1000
        )} секунд.`,
        event.reason
      );

      timeout = timeout + timeout;
      connectInterval = setTimeout(check, Math.min(10000, timeout));
    };

    const errorHandler = (error: any) => {
      console.error(
        "Ошибка соединения с вебсокетом: ",
        error.message,
        "Закрытие сокета"
      );
      webSocketChannel.close();
    };

    const messageHandler = async (event: any) => {
      const data = JSON.parse(event.data);
      switch (data.type) {
        case "dialogs":
          setLoadingDialogs(false);
          setFetchingDialogs(false);
          setDialogs(data.dialogs);
          setTotalDialog(data.count_dialogs);
          setCountUnreadMessages(data.count_all_unread);
          break;
        case "get":
          setMessages(data);
          break;
        case "new_msg_in_dialog":
          await setNewMessage(true);
          message.success("У вас новое сообщение!");
          // store.addNotification({
          //     content: <MyNotification data={data}/>,
          //     insert: "top",
          //     container: "top-right",
          //     animationIn: ["animate__animated", "animate__fadeIn"],
          //     animationOut: ["animate__animated", "animate__fadeOut"],
          //     dismiss: {
          //         duration: 5000
          //     }
          // });

          if (activeDialog.current == data.id) {
            await webSocketChannel.send(
              JSON.stringify({
                type: "get",
                dialog: data.id,
                limit: messagesLimit,
              })
            );
          }
          await webSocketChannel.send(
            JSON.stringify({
              type: "dialogs",
              user: "admin",
              anon_chats: isAnonDialogs.current,
              limit: dialogLimit,
            })
          );
          await setNewMessage(false);
          break;
      }
    };

    webSocketChannel && webSocketChannel.addEventListener("open", openHandler);
    webSocketChannel &&
      webSocketChannel.addEventListener("message", messageHandler);
    webSocketChannel &&
      webSocketChannel.addEventListener("close", closeHandler);
    webSocketChannel &&
      webSocketChannel.addEventListener("error", errorHandler);
  };

  const check = () => {
    if (!ws || ws.readyState == WebSocket.CLOSED)
      localStorage.getItem("token") &&
        testConnect(localStorage.getItem("token")); //check if websocket instance is closed, if so call `connect` function.
  };

  return (
    <ChatComponent
      ws={ws}
      dialogs={dialogs}
      getMessages={getMessages}
      messages={messages}
      dialogLimit={dialogLimit}
      dialogsCount={totalDialog}
      handleChangeLimitDialog={(limit: any) => setDialogLimit(limit)}
      handleChangeLimitMessages={(limit: any) => setMessageLimit(limit)}
      setActiveDialog={setActiveDialog}
      messagesLimit={messagesLimit}
      countUnreadMessages={countUnreadMessages}
      messagesCount={messages?.count_message || 0}
      selectedDialog={selectedDialogData}
      loadingDialogs={loadingDialogs}
      setLoadingDialogs={setLoadingDialogs}
      isAnonDialogs={isAnonDialogs}
      setIsAnonDialogs={setIsAnonDialogs}
      fetchingDialogs={fetchingDialogs}
      setFetchingDialogs={setFetchingDialogs}
    />
  );
};

export default Chat;
