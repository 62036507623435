import { Typography } from "antd";
import TransportCompaniesTable from "../components/TransportCompanies/TransportCompaniesTable";

const { Title } = Typography

const TransportCompaniesPage = () => {
    return (
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            <Title level={2}>Заказы компании</Title>
            <div style={{ marginTop: '10px' }}>
                <TransportCompaniesTable />
            </div>
        </div>
    )
}

export default TransportCompaniesPage;