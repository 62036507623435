import React, {FC, useEffect, useRef, useState} from 'react';
import {rubles} from 'rubles'
import {Button, Modal} from "antd";
import ReactToPrint from "react-to-print";


const PdfPaymentFile = ({order, orderDetails, visible, handleOk, handleClose}) => {
    const [totalPrice, setTotalPrice] = useState()
    const [totalPriceText, setTitalPriceText] = useState('')
    const tableRef = useRef(null)
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()

    useEffect(() => {
        if (orderDetails.length > 0) {
            const totalSum = orderDetails.reduce((acc, item) => acc + item.price, 0)
            setTotalPrice(totalSum)
            setTitalPriceText(rubles(totalSum))
        }
    }, [orderDetails])

    const getTextMoth = (month) => {
        switch (month) {
            case 1:
                return 'января'
            case 2:
                return 'февраля'
            case 3:
                return 'марта'
            case 4:
                return 'апреля'
            case 5:
                return 'мая'
            case 6:
                return 'июня'
            case 7:
                return 'июля'
            case 8:
                return 'августа'
            case 9:
                return 'сентября'
            case 10:
                return 'октября'
            case 11:
                return 'ноября'
            case 12:
                return 'декабря'
        }
    }

    const changePriceText = (text) => {
        const arrPriceText = text.split(' ')
        const newArrPriceText = arrPriceText.map((item, idx) => {
            if (idx === 0) {
                return item.charAt(0).toUpperCase() + item.slice(1)
            }

            return item
        })

        return newArrPriceText.join(' ')
    }

    const renderTable = () => {
        return (
            <div style={{width: '210mm', marginTop: '40px', marginLeft: 'auto', marginRight: 'auto',}}>
                <h2 style={{marginBottom: '20px'}}>Скачать/распечатать счет на оплату</h2>

                <div style={{
                    width: '210mm',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    fontSize: '11pt',

                }}>

                    <div ref={tableRef} style={{padding: '20px'}}>
                        <table width="100%" cellPadding="2" cellSpacing="2" className="invoice_bank_rekv">
                            <tr>
                                <td colSpan={2} rowSpan={2} style={{minHeight: '13mm', width: '105mm'}}>
                                    <table width="100%" cellPadding="0" cellSpacing="0"
                                           style={{height: '13mm', border: 0}}>
                                        <tr>
                                            <td valign="top" style={{paddingLeft: '3px'}}>
                                                <div>ФИЛИАЛ "НИЖЕГОРОДСКИЙ" АО "АЛЬФА-БАНК" Г.
                                                    НИЖНИЙ НОВГОРОД
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="bottom" style={{height: '3mm', paddingLeft: '3px'}}>
                                                <div style={{fontSize: '10pt'}}>Банк получателя</div>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td style={{minHeight: '7mm', height: 'auto', width: '25mm', paddingLeft: '3px'}}>
                                    <div>БИK</div>
                                </td>
                                <td rowSpan={2} style={{verticalAlign: 'top', width: '60mm', paddingLeft: '3px'}}>
                                    <div style={{height: '7mm', lineHeight: '7mm', verticalAlign: 'middle'}}>042202824
                                    </div>
                                    <div>30101810200000000824</div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: '25mm', paddingLeft: '3px'}}>
                                    <div>Сч. №</div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{minHeight: '6mm', height: 'auto', width: '50mm', paddingLeft: '3px'}}>
                                    <div>ИНН 1840100147</div>
                                </td>
                                <td style={{minHeight: '6mm', height: 'auto', width: '55mm', paddingLeft: '3px'}}>
                                    <div>КПП 184001001</div>
                                </td>
                                <td rowSpan={2}
                                    style={{
                                        minHeight: '19mm',
                                        height: 'auto',
                                        verticalAlign: 'top',
                                        width: '25mm',
                                        paddingLeft: '3px'
                                    }}>
                                    <div>Сч. №</div>
                                </td>
                                <td rowSpan={2}
                                    style={{
                                        minHeight: '19mm',
                                        height: 'auto',
                                        verticalAlign: 'top',
                                        width: '60mm',
                                        paddingLeft: '3px'
                                    }}>
                                    <div>40702810729100001495</div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2} style={{minHeight: '13mm', height: 'auto'}}>

                                    <table cellPadding="0" cellSpacing="0"
                                           style={{height: '13mm', width: '105mm', border: 0}}>
                                        <tr>
                                            <td valign="top" style={{paddingLeft: '3px'}}>
                                                <div>ООО "Партобо"</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="bottom" style={{height: '3mm', paddingLeft: '3px'}}>
                                                <div style={{fontSize: '10pt'}}>Получатель</div>
                                            </td>
                                        </tr>
                                    </table>

                                </td>
                            </tr>
                        </table>
                        <br/>

                        <div style={{fontWeight: 'bold', fontSize: '16pt', paddingLeft: '5px'}}>
                            Счет № {order.id} от {`${day} ${getTextMoth(month + 1)} ${year} г.`}
                        </div>
                        <br/>

                        <div
                            style={{
                                backgroundColor: '#000000',
                                width: '100%',
                                fontSize: '1px',
                                height: '2px'
                            }}>&nbsp;</div>

                        <table width="100%">
                            <br></br>
                            <tr>
                                <td style={{width: '30mm'}}>
                                    <div style={{paddingLeft: '2px'}}>Поставщик: <br></br> (Исполнитель)</div>
                                </td>
                                <td>
                                    <div style={{fontWeight: 'bold', paddingLeft: '2px'}}>
                                        ООО "Партобо", ИНН 1840100147, КПП 184001001, 426067, Удмуртская Респ, г. Ижевск
                                        ул.
                                        Молодежная, 111/2, офис 124, ком.20
                                    </div>
                                </td>
                            </tr>
                            <br></br>
                            <tr>
                                <td style={{width: '30mm'}}>
                                    <div style={{paddingLeft: '2px'}}>Покупатель: <br></br>(Заказчик)</div>
                                </td>
                                <td>
                                    <div style={{fontWeight: 'bold', paddingLeft: '2px'}}>
                                        {order.typePay == 'Счет на оплату' ? (
                                            <>{order.name_ooo_or_ip}, ИНН {order.inn}, {order.address}</>
                                        ) : (
                                            <>{order.customer_name} {order.address}</>
                                        )}

                                    </div>
                                </td>
                            </tr>
                            <br></br>
                            <tr>
                                <td>
                                    <div style={{paddingLeft: '2px'}}>Основание:</div>
                                </td>
                            </tr>
                        </table>


                        <table className="invoice_items" width="100%" cellPadding="2" cellSpacing="2">
                            <thead>
                            <tr>
                                <th style={{width: '13mm'}}>№</th>
                                <th>Товары (работы, услуги)</th>
                                <th style={{width: '17mm'}}>Кол-во</th>
                                <th style={{width: '17mm'}}>Ед.</th>
                                <th style={{width: '27mm'}}>Цена</th>
                                <th style={{width: '27mm'}}>Сумма</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                orderDetails.map((item, idx) => {
                                    return (
                                        <tr key={idx} style={{textAlign: 'center'}}>
                                            <td>{idx + 1}</td>
                                            <td>{item.title} {item.brand} {item.model}</td>
                                            <td>1</td>
                                            <td>шт</td>
                                            <td>{item.price}</td>
                                            <td>{item.price}</td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>

                        <table style={{border: 0}} width="100%" cellPadding="1" cellSpacing="1">
                            <tr>
                                <td></td>
                                <td style={{width: '27mm', fontWeight: 'bold', textAlign: 'right'}}>Итого:</td>
                                <td style={{width: '27mm', fontWeight: 'bold', textAlign: 'right'}}>{totalPrice}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td style={{width: '27mm', fontWeight: 'bold', textAlign: 'right'}}>Без налога (НДС):
                                </td>
                                <td style={{width: '27mm', fontWeight: 'bold', textAlign: 'right'}}>-</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td style={{width: '27mm', fontWeight: 'bold', textAlign: 'right'}}>Всего к оплате</td>
                                <td style={{width: '27mm', fontWeight: 'bold', textAlign: 'right'}}>{totalPrice}</td>
                            </tr>
                        </table>
                        <br></br>
                        <div>Всего наименований {orderDetails.length}, на сумму {totalPrice} рублей.<br/>
                            <b>{totalPriceText ? changePriceText(totalPriceText) : null}</b></div>
                        <br/>
                        <table>
                            <tr>
                                <td style={{width: '155mm'}}>
                                    <div style={{width: '155mm'}}>Внимание! Оплата данного счета означает согласие с
                                        условиями
                                        поставки товара. Уведомление об оплате обязательно, в противном случае не
                                        гарантируется
                                        наличие товара на складе. Товар отпускается по факту прихода денег на р/с
                                        Поставщика,
                                        самовывозом, при наличии доверенности и паспорта.
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <br/>
                        <div
                            style={{
                                backgroundColor: '#000000',
                                width: '100%',
                                fontSize: '1px',
                                height: '2px'
                            }}>&nbsp;</div>
                        <br/>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div>Руководитель _____________ (Емельянов А.С.)</div>
                            <div>Главный бухгалтер _____________ (Емельянов А.С.)</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <>
            {order && orderDetails.length > 0 ? (
                <Modal width={'auto'}
                       title="Счет на оплату"
                       visible={visible}
                       // onOk={}
                       onCancel={handleClose}
                       footer={[
                           <Button key="back" onClick={handleClose}>
                               Закрыть
                           </Button>,
                           <ReactToPrint
                               trigger={() => <Button key="submit" type="primary" onClick={handleOk}>
                                   Вывести на печать/скачать
                               </Button>}
                               content={() => tableRef.current}
                           />]}
                >
                    {renderTable()}
                </Modal>
            ) : (
                <Modal title="Счет на оплату" visible={visible} onOk={handleOk} onCancel={handleClose}>
                    Загрузка данных...
                </Modal>
            )
            }
        </>
    )
};

export default PdfPaymentFile;