import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {Breadcrumb, Typography, Statistic, Row, Col, Table, Tag, Space} from "antd";
import CardStat from "../components/CardStat";
import {FileDoneOutlined, LikeOutlined, DollarCircleOutlined, RetweetOutlined, StockOutlined} from "@ant-design/icons";
import TestChart from "../components/Chart/TestChart";
import TestBarChart from "../components/Chart/TestBarChart";
import TestList from "../components/Test/TestList";

const {Title} = Typography;

export interface profileInfoInterface {
    email?: string,
    first_name?: string,
    last_name?: string,
    pk?: number,
    username?: string


}

const ProfilePage: FC = () => {
    const columns = [
        {
            title: 'ФИО',
            dataIndex: 'name',
            key: 'name',
            render: (text: string) => <a>{text}</a>,
        },
        {
            title: 'Возраст',
            dataIndex: 'age',
            key: 'age',
        },
        {
            title: 'Адрес',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Тэги',
            key: 'tags',
            dataIndex: 'tags',
            render: (tags: string[]) => (
                <>
                    {tags.map(tag => {
                        let color = tag.length > 5 ? 'geekblue' : 'green';
                        if (tag === 'loser') {
                            color = 'volcano';
                        }
                        return (
                            <Tag color={color} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: 'Действия',
            key: 'action',
            render: () => (
                <Space size="middle">
                    <a>Просмотреть</a>
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            name: 'John Brown',
            age: 32,
            address: 'New York No. 1 Lake Park',
            tags: ['nice', 'developer'],
        },
        {
            key: '2',
            name: 'Jim Green',
            age: 42,
            address: 'London No. 1 Lake Park',
            tags: ['loser'],
        },
        {
            key: '3',
            name: 'Joe Black',
            age: 32,
            address: 'Sidney No. 1 Lake Park',
            tags: ['cool', 'teacher'],
        },
    ];

    return (
        <>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item><Link to={'/'}>Главная</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to={'/profile'}>Профиль</Link></Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{padding: 24, minHeight: 360, position: 'relative'}}>
                <div
                    className="develop-overlay"
                    style={{
                        zIndex: 2,
                        backgroundColor: '#ffffffa1',
                        position: 'absolute',
                        width: 'calc(100% - 20px)',
                        height: 'calc(100% - 20px)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '50px',
                        fontWeight: 500,
                        color: '#909090',
                    }}
                >Раздел в разработке</div>
                <Title level={2}>Профиль</Title>
                <Row gutter={[16, 16]}>
                    <Col lg={6} md={12} xs={24}>
                        <CardStat
                            icon={<FileDoneOutlined style={{color: '#04b9d7', background: '#e3f6f9'}} />}
                            count={'3021'}
                            cardTitle={'Всего заказов'}
                            typeFooter={'link'}
                            text={'Все заказы'}
                        />
                    </Col>
                    <Col lg={6} md={12} xs={24}>
                        <CardStat
                            icon={<DollarCircleOutlined style={{color: 'rgb(76, 175, 80)', background: 'rgba(76, 175, 80, 0.08)'}}/>}
                            count={'3010'}
                            cardTitle={'Продано деталей'}
                            typeFooter={'statUp'}
                            text={'На 12% больше прошлого месяца'}
                        />
                    </Col>

                    <Col lg={6} md={12} xs={24}>
                        <CardStat
                            icon={<RetweetOutlined style={{color: 'rgb(244, 67, 54)', background: 'rgba(244, 67, 54, 0.08)'}}/>}
                            count={'11'}
                            cardTitle={'Всего возвратов'}
                            typeFooter={'statDown'}
                            text={'На 30% меньше прошлого месяца'}
                        />
                    </Col>
                    <Col lg={6} md={12} xs={24}>
                        <CardStat
                            icon={<StockOutlined style={{color: '#feb60a', background: '#fdf5e4'}}/>}
                            count={'112,000 ₽'}
                            cardTitle={'Прибыль'}
                            typeFooter={'statUp'}
                            text={'На 12% больше прошлого месяца'}
                        />
                    </Col>
                    <Col lg={6} xs={24}>
                        <div className={'card-stat'} style={{padding: '10px'}}>
                            <TestBarChart/>
                            <TestList/>
                        </div>
                    </Col>
                    <Col lg={18} xs={24}>
                        <div className={'card-stat'}>
                            <TestChart/>
                        </div>
                    </Col>
                    <Col lg={6} xs={24}>
                        <div className={'card-stat'} style={{padding: '10px'}}>
                            <Row gutter={16}>
                                <Col span={24} style={{textAlign: 'center'}}>
                                    <Statistic title="Feedback" value={1128} prefix={<LikeOutlined />} />
                                </Col>
                                <Col span={24} style={{textAlign: 'center'}}>
                                    <Statistic title="Unmerged" value={93} suffix="/ 100" />
                                </Col>
                                <Col span={24} style={{textAlign: 'center'}}>
                                    <Statistic title="Unmerged" value={93} suffix="/ 100" />
                                </Col>
                                <Col span={24} style={{textAlign: 'center'}}>
                                    <Statistic title="Unmerged" value={93} suffix="/ 100" />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col lg={18} xs={24}>
                        <div className={'card-stat'} style={{padding: '10px'}}>
                            <Table dataSource={data} columns={columns} pagination={false} />
                        </div>
                    </Col>
                </Row>

            </div>
        </>
    );
};

export default ProfilePage;