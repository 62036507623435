import React, {FormEvent, useRef, useState} from 'react';
import {message, Modal, Input, Form} from "antd";
import {EyeOutlined, EyeInvisibleOutlined, MailOutlined} from "@ant-design/icons";
import {Link, useHistory} from 'react-router-dom'
import {restApi} from "../utils/variables";
import '../styles/login.css'

interface LoginData {
    email: string,
    password: string
}

const Login = () => {
    let history = useHistory();
    const [showPassword, setShowPassword] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm()

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        form.validateFields()
            .then(async (values) => {
                await resetPassword(values.email)
            })
            .catch((errorInfo) => {
            })
    };

    const resetPassword = async (email: string) => {
        const url = `${restApi}/accounting_rest/password-reset/?email=${email}`
        console.log(url)
        try {
            const res = await fetch(url)
            const json = await res.json()
            console.log(json)
            setIsModalVisible(false)
            message.success(`Новый пароль отправлен к вам на почту ${email}`)
        } catch (e) {
            console.log('Что-то пошло не так', e)
        }
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        const target = e.target as HTMLFormElement

        if (target.email && target.password && (target.email.value !== '' && target.password.value !== '')) {
            const formData = {
                email: target.email.value,
                password: target.password.value
            }
            await Login(formData)
        } else {
            message.error('Заполните все необходимые поля для авторизации!')
        }

    }

    const Login = async (formData: LoginData) => {
        try {
            const url = `${restApi}/accounting_rest/login/`
            const res = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {
                    'Content-type': 'application/json'
                }
            })
            const json = await res.json()

            switch (json.status) {
                case "no_such_email":
                    message.error('Аккаунта с таким email адресом не существует')
                    break;
                case "invalid_password":
                    message.error('Неверный пароль, попробуйте снова или воостановите пароль')
                    break;
                case 'ok':
                    message.success('Вы успешно авторизовались');
                    localStorage.setItem('token', json.token);
                    window.location.reload();
                    break;
                default:
                    message.error('Неизвестный статус ответа от сервера')
                    break
            }

        } catch (e) {
            message.error('Ошибка соединения с сервером')
            console.log('Что-то пошло не так...', e)
        }
    }

    return (
        <>
            <Modal title="Форма восстановления пароля" visible={isModalVisible} okText={'Подтвердить'}
                   cancelText={'Закрыть'} onOk={handleOk} onCancel={handleCancel}>
                <Form layout="vertical"
                      form={form}
                      validateMessages={{
                          required: '${label} обязательное поле!',
                          types: {
                              email: 'Пожалуйста введите валидный ${label}!',
                          }
                      }
                      }
                >
                    <Form.Item
                        name="email"
                        label="Email адрес"
                        rules={[{type: 'email', required: true}]}
                    >
                        <Input size="large" placeholder="Введите email адрес" prefix={<MailOutlined/>}/>
                    </Form.Item>
                </Form>
                <p>Введите email адрес вашей учетной записи для восстановления пароля</p>
            </Modal>
            <div className={'login-wrapper'}>
                <div className="form-container form-reg">
                    <div className="form-title">
                        Авторизация
                    </div>
                    {/*<div className="form-socials">*/}
                    {/*    <div className="form-socials__item vk">*/}
                    {/*        <img src="https://static.tildacdn.com/tild3731-3236-4364-b266-336436626566/photo.png"*/}
                    {/*             alt="Авторизация через VK"/>*/}
                    {/*        <span>Вконтакте</span>*/}
                    {/*    </div>*/}
                    {/*    <div className="form-socials__item google">*/}
                    {/*        <img*/}
                    {/*            src="https://lh4.googleusercontent.com/-JGT2qEoG5X4/AAAAAAAAAAI/AAAAAAAAHko/fH9KqPJv1Ls/photo.jpg?sz=5064"*/}
                    {/*            alt="Авторизация через Google"/>*/}
                    {/*        <span>Google</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <input name={'email'} type="email" placeholder={'Введите ваш email адрес'}/>
                        <div className="input-password-container">
                            <input name={'password'} type={!showPassword ? 'password' : 'text'}
                                   placeholder={'Введите ваш пароль'}/>
                            {!showPassword ? <EyeOutlined onClick={() => setShowPassword(!showPassword)}/> :
                                <EyeInvisibleOutlined onClick={() => setShowPassword(!showPassword)}/>}
                        </div>
                        <span className={'password-recover'} onClick={() => showModal()}>Забыли пароль?</span>
                        <button type={'submit'}>Войти</button>
                    </form>
                    {/*<div className="form-notice">*/}
                    {/*    <span>Нет регистрации?</span> <Link to={'/registration'}>Зарегистрируйтесь</Link>*/}
                    {/*</div>*/}
                </div>
            </div>
        </>
    );
};

export default Login;