import React, { FC, useEffect, useRef, useState } from "react";
import ChatMessages from "./ChatMessages";
import moment from "moment";
import "moment/locale/ru";
import { Badge, Dropdown, Menu, Modal, Row, Spin, Tabs } from "antd";
import {
  DownOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  FullscreenOutlined,
  UpOutlined,
  WechatOutlined,
} from "@ant-design/icons/lib";
import AnalyticsUserActions from "../Analytics/AnalyticsUserActions";
// import { useTypedSelector } from "../../hooks/useTypedSelector";

const ChatComponent: FC<any> = ({
  dialogs,
  getMessages,
  messages,
  dialogLimit,
  handleChangeLimitDialog,
  dialogsCount,
  handleChangeLimitMessages,
  setActiveDialog,
  messagesLimit,
  messagesCount,
  countUnreadMessages,
  ws,
  loadingDialogs,
  setLoadingDialogs,
  isAnonDialogs,
  setIsAnonDialogs,
  fetchingDialogs,
  setFetchingDialogs,
}) => {
  const [disclosed, setDisclosed] = useState(false);
  const [fullWidthModal, setFullWidthModal] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [testMessages, setTestMessages] = useState<any[]>([]);
  const dialogsRef = useRef<any>();
  const messagesEndRef = useRef<any>();
  const messagesRef = useRef();
  const [fetching, setFetching] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const currentDialogTop = useRef(0);
  const [currentDialog, setCurrentDialog] = useState(null);

  // const selectedDialog = store.currentChat;
  //anon dialogs
  const [anonDialogs, setAnonDialogs] = useState([]);
  const [anonTabActive, setAnonTabActive] = useState(false);

  //user actions
  const [openActionsModal, setOpenActionsModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  // useEffect(() => {
  //   if (selectedDialog?.id) {
  //     setCurrentDialog(selectedDialog);
  //     setActiveDialog(selectedDialog.id);
  //     getMessages(selectedDialog.id, selectedDialog);
  //     setShowDialog(true);
  //     setDisclosed(true);
  //     if (ws && ws.readyState) {
  //       setLoadingDialogs(true);
  //       ws.send(
  //         JSON.stringify({
  //           type: "dialogs",
  //           user: "admin",
  //           limit: dialogLimit,
  //         })
  //       );
  //     }
  //   }
  // }, [selectedDialog]);

  useEffect(() => {
    if (dialogsRef && dialogsRef.current) {
      dialogsRef.current.addEventListener("scroll", handleScrollDialogs);

      return function () {
        dialogsRef?.current?.removeEventListener("scroll", handleScrollDialogs);
      };
    }
  }, [dialogsRef, dialogs, dialogsCount, showDialog]);

  useEffect(() => {
    if (!showDialog && currentDialogTop.current !== 0) {
      dialogsRef?.current?.scrollTo(0, currentDialogTop.current);
    }
  }, [showDialog]);

  useEffect(() => {
    if (
      showDialog &&
      messagesEndRef &&
      messagesEndRef.current &&
      !loadingMessages
    ) {
      setTimeout(() => {
        messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
      }, 10);
    }
  }, [showDialog, messagesEndRef, loadingMessages]);

  useEffect(() => {
    if (messages?.messages?.length > 0) {
      const test = messages.messages.map((message: any) => {
        return {
          ...message,
          dateParse: getDate(new Date(message.date)),
          time: moment(message.date).format("LT"),
        };
      });
      const groupedMessages = groupBy(test, "dateParse");
      const newArr = Object.entries(groupedMessages).map((item) => {
        return {
          group: item[0],
          messages: item[1] ? [...(item[1] as any)] : [],
        };
      });
      setTestMessages(newArr);
      setTimeout(() => {
        setLoadingMessages(false);
      }, 10);
    } else {
      setTestMessages([]);
      setTimeout(() => {
        setLoadingMessages(false);
      }, 10);
    }
  }, [messages]);

  useEffect(() => {
    if (fetchingDialogs) {
      setTimeout(() => {
        handleChangeLimitDialog(dialogLimit + 10);
        // setFetching(false);
      }, 1000);
    }
  }, [fetchingDialogs]);

  const handleScrollDialogs = (e: any) => {
    const isFetch = e.target.scrollHeight - e.target.scrollTop <= 450;
    if (
      isFetch &&
      dialogsCount > dialogs.length &&
      dialogsCount !== dialogs.length &&
      !fetchingDialogs
    ) {
      setFetchingDialogs(true);
    }
  };

  const groupBy = (array: any, key: any) => {
    return array.reduce((result: any, currentItem: any) => {
      (result[currentItem[key]] = result[currentItem[key]] || []).push(
        currentItem
      );
      return result;
    }, {});
  };

  const getDate = (date: any) => {
    moment().locale("ru");
    if (moment().diff(date, "days") >= 1) {
      return moment(date).format("MMM Do YY");
    }

    return moment(date).calendar().split(",")[0];
  };

  const handleDisclose = () => {
    setDisclosed(!disclosed);
    setFullWidthModal(false);
  };

  const getTime = (date: any) => {
    moment().locale("ru");
    if (moment().diff(date, "days") >= 1) {
      return moment(date).format("MMM Do YY");
    }
    return moment(date).calendar();
  };

  const menu = (dialog: any) => (
    <Menu>
      <Menu.Item key="1" onClick={() => handleClickDropdown(dialog)}>
        Список действий
      </Menu.Item>
    </Menu>
  );

  const handleClickDropdown = (dialog: any) => {
    let userObj: any = {};
    if (dialog?.customer_id) {
      userObj = { user_id: dialog.customer_id };
    } else {
      userObj = { token: dialog.token_analytics };
    }
    setSelectedUser(userObj);
    setOpenActionsModal(true);
  };

  const handleCloseModal = () => {
    setOpenActionsModal(false);
    setSelectedUser(null);
  };

  const renderDialogs = (dialogs: any) => {
    return (
      <>
        {dialogs.map((dialog: any) => {
          return (
            <Dropdown overlay={menu(dialog)} trigger={["contextMenu"]}>
              <div
                className={"dialogs-item"}
                onClick={(e) => {
                  e.preventDefault();
                  getMessages(dialog.id, dialog);
                  if (dialogsRef?.current) {
                    currentDialogTop.current =
                      dialogsRef?.current.scrollTop || 0;
                  }
                  setShowDialog(true);
                  setLoadingMessages(true);
                  setCurrentDialog(dialog);
                  setActiveDialog(dialog.id);
                }}
              >
                <Badge count={dialog.count_unread}>
                  <img
                    onError={(e: any) =>
                      (e.target.src = `https://i.pinimg.com/originals/8a/eb/d8/8aebd875fbddd22bf3971c3a7159bdc7.png`)
                    }
                    src={
                      dialog?.photo ||
                      `https://i.pinimg.com/originals/8a/eb/d8/8aebd875fbddd22bf3971c3a7159bdc7.png`
                    }
                    alt={dialog.detail}
                  />
                </Badge>
                <div className={"dialogs-info"}>
                  <b>{dialog.company}</b>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span>{dialog.detail ? dialog.detail : ""}</span>
                    {dialog.last_msg ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ color: "#000" }}>
                          {dialog.last_msg.text
                            ? dialog.last_msg.text
                            : dialog.last_msg.text == "" &&
                              dialog.last_msg.photo
                            ? "Фото"
                            : "Пустое сообщение"}
                        </span>
                        {dialog.last_msg.view ? (
                          <EyeOutlined style={{ marginLeft: 5 }} />
                        ) : (
                          <EyeInvisibleOutlined style={{ marginLeft: 5 }} />
                        )}
                      </div>
                    ) : null}
                  </div>
                  <div className={"dialogs-time"}>
                    {dialog.last_msg.date
                      ? getTime(new Date(dialog.last_msg.date))
                      : ""}
                  </div>
                </div>
              </div>
            </Dropdown>
          );
        })}
        {fetchingDialogs && (
          <Row justify={"center"}>
            <Spin tip="Загрузка..." />
          </Row>
        )}
        <Modal
          title={`Список действий пользователя`}
          visible={openActionsModal}
          onCancel={handleCloseModal}
          width={600}
          footer={[]}
        >
          <AnalyticsUserActions user={selectedUser} />
        </Modal>
      </>
    );
  };

  const renderNoDialogs = () => {
    return (
      <div className={"no-dialogs"}>
        <h1>У вас пока нет активных диалогов</h1>
      </div>
    );
  };

  const handleChangeChat = (value: string) => {
    if (value === "1") {
      setIsAnonDialogs(false);
      setLoadingDialogs(true);
      ws.send(
        JSON.stringify({
          type: "dialogs",
          user: "admin",
          limit: dialogLimit,
        })
      );
    } else {
      if (ws && ws.readyState) {
        setLoadingDialogs(true);
        ws.send(
          JSON.stringify({
            type: "dialogs",
            user: "admin",
            anon_chats: true,
            limit: dialogLimit,
          })
        );
      }
      setIsAnonDialogs(true);
    }
  };

  return (
    <div
      className={`${
        disclosed && fullWidthModal
          ? "chat-modal full"
          : disclosed && !fullWidthModal
          ? "chat-modal open"
          : "chat-modal"
      }`}
    >
      <div className={"chat-header"} onClick={handleDisclose}>
        <WechatOutlined />
        <Badge count={countUnreadMessages}>
          <span style={{ color: "#fff" }}>Сообщения</span>
        </Badge>
        <div>
          {disclosed ? (
            <FullscreenOutlined
              style={{ marginRight: "10px" }}
              onClick={(e) => {
                e.stopPropagation();
                setFullWidthModal(!fullWidthModal);
              }}
            />
          ) : null}

          {disclosed ? <DownOutlined /> : <UpOutlined />}
        </div>
      </div>
      <div className={"chat-content"}>
        {showDialog && loadingMessages ? (
          <div className={"messages loading"}>
            <Spin tip={"Загрузка сообщений..."} />
          </div>
        ) : showDialog && !loadingMessages ? (
          <ChatMessages
            currentDialog={currentDialog}
            handleBack={() => {
              setShowDialog(false);
              setActiveDialog(null);
              handleChangeLimitMessages(10);
            }}
            getMessages={getMessages}
            messagesCount={messagesCount}
            handleChangeLimitMessages={(limit: any) =>
              handleChangeLimitMessages(limit)
            }
            testMessages={testMessages}
            messages={messages}
            messagesLimit={messagesLimit}
            ws={ws}
          />
        ) : (
          <>
            <div className="chat-support">
              <a
                href="https://vk.com/club37192159"
                target={"_blank"}
                className={`chat-support__item vk`}
              >
                <svg
                  className={"MuiSvgIcon-root"}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    className="st0"
                    d="M13.162 18.994c.609 0 .858-.406.851-.915-.031-1.917.714-2.949 2.059-1.604 1.488 1.488 1.796 2.519 3.603 2.519h3.2c.808 0 1.126-.26 1.126-.668 0-.863-1.421-2.386-2.625-3.504-1.686-1.565-1.765-1.602-.313-3.486 1.801-2.339 4.157-5.336 2.073-5.336h-3.981c-.772 0-.828.435-1.103 1.083-.995 2.347-2.886 5.387-3.604 4.922-.751-.485-.407-2.406-.35-5.261.015-.754.011-1.271-1.141-1.539-.629-.145-1.241-.205-1.809-.205-2.273 0-3.841.953-2.95 1.119 1.571.293 1.42 3.692 1.054 5.16-.638 2.556-3.036-2.024-4.035-4.305-.241-.548-.315-.974-1.175-.974h-3.255c-.492 0-.787.16-.787.516 0 .602 2.96 6.72 5.786 9.77 2.756 2.975 5.48 2.708 7.376 2.708z"
                  />
                </svg>
                <span>Вконтакте</span>
              </a>
              <a
                className={`chat-support__item telegram`}
                href="https://t.me/partoboSupport"
                target={"_blank"}
              >
                <svg
                  width="24px"
                  className={"MuiSvgIcon-root"}
                  height="24px"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  // xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"
                  // xmlns:serif="http://www.serif.com/"
                  style={{
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 1.41421,
                  }}
                >
                  <path
                    id="telegram-1"
                    d="M18.384,22.779c0.322,0.228 0.737,0.285 1.107,0.145c0.37,-0.141 0.642,-0.457 0.724,-0.84c0.869,-4.084 2.977,-14.421 3.768,-18.136c0.06,-0.28 -0.04,-0.571 -0.26,-0.758c-0.22,-0.187 -0.525,-0.241 -0.797,-0.14c-4.193,1.552 -17.106,6.397 -22.384,8.35c-0.335,0.124 -0.553,0.446 -0.542,0.799c0.012,0.354 0.25,0.661 0.593,0.764c2.367,0.708 5.474,1.693 5.474,1.693c0,0 1.452,4.385 2.209,6.615c0.095,0.28 0.314,0.5 0.603,0.576c0.288,0.075 0.596,-0.004 0.811,-0.207c1.216,-1.148 3.096,-2.923 3.096,-2.923c0,0 3.572,2.619 5.598,4.062Zm-11.01,-8.677l1.679,5.538l0.373,-3.507c0,0 6.487,-5.851 10.185,-9.186c0.108,-0.098 0.123,-0.262 0.033,-0.377c-0.089,-0.115 -0.253,-0.142 -0.376,-0.064c-4.286,2.737 -11.894,7.596 -11.894,7.596Z"
                  />
                </svg>
                <span>Telegram</span>
              </a>
              <a
                className={`chat-support__item whatsapp`}
                href="https://api.whatsapp.com/send?phone=79635451711"
                target={"_blank"}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
                </svg>
                <span>WhatsApp</span>
              </a>
            </div>

            <div className="dialogs" ref={dialogsRef}>
              <Tabs
                activeKey={isAnonDialogs.current ? "2" : "1"}
                onChange={handleChangeChat}
              >
                <Tabs.TabPane
                  tab="Общий чат"
                  key="1"
                  disabled={fetchingDialogs || loadingDialogs}
                >
                  {loadingDialogs ? (
                    <div className={"messages loading"}>
                      <Spin tip={"Загрузка диалогов..."} />
                    </div>
                  ) : dialogs.length > 0 ? (
                    renderDialogs(dialogs)
                  ) : (
                    renderNoDialogs()
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab="Вопросы администрации"
                  key="2"
                  disabled={fetchingDialogs || loadingDialogs}
                >
                  {loadingDialogs ? (
                    <div className={"messages loading"}>
                      <Spin tip={"Загрузка диалогов..."} />
                    </div>
                  ) : isAnonDialogs.current && dialogs.length > 0 ? (
                    renderDialogs(dialogs)
                  ) : (
                    renderNoDialogs()
                  )}
                </Tabs.TabPane>
              </Tabs>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default ChatComponent;
