import { message, Modal, Pagination } from "antd";
import { useEffect, useState } from "react";
import { restApi } from "../../utils/variables";
import AnalyticsAnonUserList from "./AnalyticsAnonUserList";
import AnalyticsUserActions from "./AnalyticsUserActions";

//analytics/list_users/
type AnalyticsUsersBody = {
  page: number;
  type_user?: "anon" | "user";
  search_keyword?: string;
};

const AnalyticsAnon = () => {
  //table users
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [totalPagesTableUsers, setTotalPagesTableUsers] = useState(1);

  //actions modal
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [openActionsModal, setOpenActionsModal] = useState(false);

  useEffect(() => {
    getUsers({ page: 1 });
  }, []);

  const getUsers = async (body: AnalyticsUsersBody) => {
    try {
      setLoadingUsers(true);
      const url = `${restApi}/analytics/list_users/`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const json = await response.json();

      if (json.status === "ok") {
        setUsers(json.users);
        setTotalPagesTableUsers(json.pages.total_pages);
      } else {
        throw Error(response.statusText);
      }
    } catch (e: any) {
      message.error(e.message);
    } finally {
      setLoadingUsers(false);
    }
  };

  const handleChangePageUsers = async (page: number) => {
    getUsers({ page });
  };

  const handleOpenModal = (user: any) => {
    setSelectedUser(user);
    setOpenActionsModal(true);
  };

  const handleCloseModal = () => {
    setOpenActionsModal(false);
    setSelectedUser(null);
  };
  return (
    <>
      <AnalyticsAnonUserList
        loadingUsers={loadingUsers}
        users={users}
        handleOpenModal={handleOpenModal}
      />
      <Pagination
        style={{ marginTop: 10 }}
        defaultCurrent={1}
        onChange={handleChangePageUsers}
        total={totalPagesTableUsers * 10}
        showSizeChanger={false}
      />

      <Modal
        title={`${selectedUser?.hash_name}`}
        open={openActionsModal}
        onCancel={handleCloseModal}
        width={600}
        footer={[]}
      >
        <AnalyticsUserActions user={selectedUser} />
      </Modal>
    </>
  );
};

export default AnalyticsAnon;
