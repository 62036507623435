import { EditOutlined } from "@ant-design/icons";
import { Button, message, Space, Table, Tooltip } from "antd"
import { useEffect, useState } from "react";
import { restApi } from "../../utils/variables";
import TransportCompanyModal from "./TransportCompanyModal";

const TransportCompaniesTable = () => {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);

    //modal
    const [open, setOpen] = useState(false);
    const [selectedTC, setSelectedTC] = useState(null);

    useEffect(() => {
        getTransportCompanies();
    }, []);

    const getTransportCompanies = async (page = 1) => {
        try {
            setLoading(true);
            const url = `${restApi}/sp/delivery_companies/?page=${page}`;
            const response = await fetch(url, {
                headers: {
                    Authorization: `Token ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                }
            })
            const json = await response.json();
            setData(json.results)
            setTotal(json.count);
        } catch (e) {
            message.error('Ошибка получения списка транспортных компаний');
            console.log('get transport companies error', e);
        } finally {
            setLoading(false);
        }
    }

    const columns: any[] = [
        {
            title: '',
            key: 'action',
            width: 50,
            render: (row: any) => (
                <Space size="middle">
                    <Tooltip title="Редактировать">
                        <Button shape="circle" icon={<EditOutlined />} onClick={() => {
                            setSelectedTC(row);
                            setOpen(true);
                        }} />
                    </Tooltip>

                </Space>
            ),
        },
        { title: 'ID', dataIndex: 'id', key: 'id', width: 100, },
        { title: 'Название', dataIndex: 'title', key: 'title' },
    ];

    const handleTableChange = async (
        pagination: any,
    ) => {
        const { current } = pagination;
        setPage(current);
        getTransportCompanies(current);
    }

    return (
        <>
            <Button style={{ marginBottom: 10 }} onClick={() => setOpen(true)} type="primary">Добавить новую ТК</Button>
            <Table
                onChange={handleTableChange}
                columns={columns}
                dataSource={data}
                loading={loading}
                pagination={{
                    total,
                    pageSize: total,
                    showSizeChanger: false,
                }} />

            <TransportCompanyModal
                open={open}
                onClose={() => {
                    setOpen(false);
                    setSelectedTC(null);
                    getTransportCompanies(page);
                }
                }
                data={selectedTC}
            />
        </>
    );
}

export default TransportCompaniesTable;