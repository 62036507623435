import React, {FC, useEffect, useState} from 'react';
import {
    Breadcrumb,
    message,
    Pagination,
    Select,
    Table,
    Badge,
    Button, Drawer, Space, Tooltip, Modal, Form, Row, Col, Input, Switch
} from "antd";
import {Link} from "react-router-dom";
import {CompanyInfo} from "../components/ListCompanies";
import {restApi} from "../utils/variables";
import {PlusOutlined, ArrowDownOutlined, EditOutlined} from '@ant-design/icons';
import {SelectOptions} from "./CompanyEdit";

const {Option} = Select

const Partners: FC = () => {
    const [companies, setCompanies] = useState<CompanyInfo[]>([])
    const [loadingCompanies, setLoadingCompanies] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(1)
    const [visibleAdd, setVisibleAdd] = useState(false)
    const [currentEditPartner, setCurrentEditPartner] = useState<any | undefined>(undefined)
    const [visiblePartnerModal, setVisiblePartnerModal] = useState(false)
    const [typeAddress, setTypeAddress] = useState(false)
    const [countries, setCountries] = useState<SelectOptions[]>([])
    const [regions, setRegions] = useState([])
    const [cities, setCities] = useState([])
    const [country, setCountry] = useState<any>(undefined)
    const [region, setRegion] = useState<any>(undefined)
    const [city, setCity] = useState<any>(undefined)
    const [disableSaveBtn, setDisableSaveBtn] = useState(true)
    const [AddForm] = Form.useForm()
    const [EditForm] = Form.useForm()

    useEffect(() => {
        getCompanies()
        getCountries()

    }, [])

    useEffect(() => {
        const fixAutocomplete = () => {
            document.querySelectorAll(".ant-select-selector input").forEach((e) => {
                e.setAttribute("autocomplete", "stopDamnAutocomplete");
                //you can put any value but NOT "off" or "false" because they DO NOT works
            })
        }

        if (typeAddress) {
            fixAutocomplete()
        }

    }, [typeAddress])

    const getCountries = async () => {
        try {
            const url = `${restApi}/accounting_rest/countries/`
            const res = await fetch(url, {
                method: "GET",
                headers: {'Authorization': `Token ${localStorage.getItem('token')}`}
            })
            const json = await res.json()

            setCountries(json.results)
        } catch (e) {
            message.error('Ошибка получения стран');
            console.log(e)
        }
    }

    const getRegions = async (value: any, id?: any) => {
        try {
            let url
            if (id) {
                url = `${restApi}/accounting_rest/regions/?country=${id}`
            } else {
                url = `${restApi}/accounting_rest/regions/?country=${value['data-id']}`
            }
            const res = await fetch(url, {
                method: "GET",
                headers: {'Authorization': `Token ${localStorage.getItem('token')}`}
            })
            const json = await res.json()
            const formatedData = json.results.map((item: any) => {
                return {
                    ...item,
                    alternate_names: item.alternate_names.split(';')[0]
                }
            })
            setRegions(formatedData)
        } catch (e) {
            message.error('Ошибка получения регионов');
            console.log(e)
        }
    }

    const getCities = async (value: any, id?: any) => {
        try {
            let url
            if (id) {
                url = `${restApi}/accounting_rest/cities/?region=${id}`
            } else {
                url = `${restApi}/accounting_rest/cities/?region=${value['data-id']}`
            }
            const res = await fetch(url, {
                method: "GET",
                headers: {'Authorization': `Token ${localStorage.getItem('token')}`}
            })
            const json = await res.json()
            const formatedData = json.results.map((item: any) => {
                return {
                    ...item,
                    alternate_names: item.alternate_names.split(';')[0]
                }
            })
            setCities(formatedData)
        } catch (e) {
            message.error('Ошибка получения городов');
            console.log(e)
        }
    }

    const renderSelectOptions = (options: SelectOptions[]) => {
        return options.map((option) => {
            return <Option key={option.id} data-id={option.id}
                           value={option.alternate_names}>{option.alternate_names}</Option>
        })
    }


    const getCompanies = async (page = 1, verification?: any, newParts?: any) => {
        setLoadingCompanies(true)
        try {
            let url =
                page && verification && newParts ? `${restApi}/updater/companies/?page=${page}&verification=${verification}&new_parts=${newParts}` :
                    page && verification && !newParts ? `${restApi}/updater/companies/?page=${page}&verification=${verification}` :
                        page && !verification && newParts ? `${restApi}/updater/companies/?page=${page}&new_parts=${newParts}` : `${restApi}/updater/companies/?page=${page}`

            const response = await fetch(url, {
                headers: {
                    'Authorization': `Token ${localStorage.getItem('token')}`
                }
            })
            const json = await response.json()
            setCompanies(json.results)
            setPageSize(json.count)
            setLoadingCompanies(false)
        } catch (e) {
            setLoadingCompanies(false)
            message.error('Ошибка загрузки списка поставщиков')
            console.log('Что-то пошло не так...', e)
        }
    }

    const changeInfoCompany = async (data : any, id: any) => {
        try {
            const url = `${restApi}/updater/companies_extended/${id}/`
            await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Token ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({...data})
            })
            message.success('Редактирование прошло успешно')
        }catch (e) {
            console.log('error change company', e)
            message.error('Ошибка редактирования компании')
        }
    }

    const addCompany = async (data: any) => {
        try {
            const url = `${restApi}/updater/companies_extended/`
            await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({...data})
            })
            message.success('Компания успешно добавлена!')
        }catch (e) {
            console.log('add company error', e)
            message.error('Ошибка создания компании')
        }
    }


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 100,
        },
        {
            title: 'Название компании',
            dataIndex: 'title',
            key: 'title',
            width: 200,
        },
        {
            title: 'Запчасти',
            dataIndex: 'new_parts',
            key: 'new_parts',
            width: 120,
            filters: [
                {
                    text: 'Б/У',
                    value: false,
                },
                {
                    text: 'Новые',
                    value: true,
                },
            ],
            onFilter: (value: any, record: any) => {
                console.log(value, record)
                return record.new_parts == value
            },
            render: (newParts: boolean) => newParts ? 'Новые' : 'Б/У'
        },
        {
            title: 'Верификация',
            dataIndex: 'verification',
            key: 'verification',
            width: 150,
            filters: [
                {
                    text: 'Пройдена',
                    value: true,
                },
                {
                    text: 'Не пройдена',
                    value: false,
                },
            ],
            onFilter: (value: any, record: any) => {
                console.log(value, record)
                return record.verification == value
            },

            render: (verification: boolean) => verification ? (<Badge status="success" text="Пройдена"/>) : (
                <Badge status="error" text="Не пройдена"/>)
        },
        {
            title: 'Страна',
            dataIndex: 'country',
            key: 'country',
            width: 200,
        },
        {
            title: 'Регион',
            dataIndex: 'region',
            key: 'region',
            width: 200,
        },
        {
            title: 'Адрес',
            key: 'address',
            width: 200,
            render: (data: any) => data && data.city ? `${data.city}` : '-'
        },
        {
            title: 'Телефон',
            dataIndex: 'telephone_number',
            key: 'telephone_number',
            width: 200,
        },
        {
            title: 'Email',
            dataIndex: 'e_mail',
            key: 'e_mail',
            width: 200,
        },
        {
            title: 'Сайт',
            dataIndex: 'site_company',
            key: 'site_company',
            width: 200,
        },
        {
            title: 'Действия',
            key: 'action',
            width: 100,
            render: (partner: any) => (
                <Space size="middle">
                    <Tooltip title="Редактировать">
                        <Button shape="circle" icon={<EditOutlined/>} onClick={() => handleEditPartner(partner)}/>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const handleEditPartner = async (partner: any) => {
        EditForm.resetFields()
        console.log(partner)
        setCurrentEditPartner(partner)
        setCountry(undefined)
        setRegion(undefined)
        setCity(undefined)
        setTypeAddress(false)
        if (partner.address) {
            setTypeAddress(true)
            const addr = JSON.parse(partner.address)

            if (addr && addr.country && addr.region && addr.city) {
                console.log('address parsed', addr)
                await getRegions(undefined, addr.country.id)
                await getCities(undefined, addr.region.id)
                EditForm.setFieldsValue({
                    country: addr.country.alternate_names,
                    region: addr.region.alternate_names,
                    city: addr.city.alternate_names
                })
            } else {
                EditForm.setFieldsValue({country: '', region: '', city: ''})
            }
        }

        EditForm.setFieldsValue({
            file: partner.upload_file,
            title: partner.title,
            email: partner.e_mail,
            new_parts: partner.new_parts ? 'Новые' : 'Б/У',
            site: partner.site_company,
            phone: partner.telephone_number,
            verification: partner.verification ? 'Пройдена' : 'Не пройдена',
            street: partner.street ? partner.street : '',
            house: partner.house ? partner.house : '',
            address: partner.country ? `${partner.country}, ${partner.region}, ${partner.city}, ${partner.street}, ${partner.house}` : ''
        })
        setVisiblePartnerModal(true)
    }

    const handleChangeInfoPartner = () => {

        console.log(country, region, city)
        EditForm.validateFields().then(values => {

            let address

            if (city && region && country) {
                address = {
                    country,
                    region,
                    city
                }
            }else {
                address = {}
            }

            const formatData = {
                address: JSON.stringify(address),
                city: values.city ? values.city : '',
                country: values.country ? values.country : '',
                e_mail: values.email ? values.email : '',
                house: values.house ? values.house : '',
                new_parts: values.new_parts == "Б/У" ? false : true,
                region: values.region ? values.region : '',
                site_company: values.site ? values.site : '',
                street: values.street ? values.street : '',
                telephone_number: values.phone ? values.phone : '',
                title: values.title ? values.title : '',
                verification: values.verification == "Пройдена" ? true : false
            }

            if (currentEditPartner && currentEditPartner.id) {
                changeInfoCompany(formatData, currentEditPartner.id)
                getCompanies(currentPage)

            }else {
                message.error('Непредвиденная ошибка')
            }

            setVisiblePartnerModal(false)
        })
    }

    const handleAddCompany = () => {
        AddForm.validateFields().then(values => {
            // address: "{\"country\":{\"id\":1,\"name\":\"Russia\",\"name_ascii\":\"Russia\",\"slug\":\"russia\",\"geoname_id\":2017370,\"alternate_names\":\"Российская Федерация\",\"code2\":\"RU\",\"code3\":\"RUS\",\"continent\":\"EU\",\"tld\":\"ru\",\"phone\":\"7\"},\"region\":{\"id\":6,\"name\":\"Udmurtiya Republic\",\"name_ascii\":\"Udmurtiya Republic\",\"slug\":\"udmurtiya-republic\",\"geoname_id\":479613,\"alternate_names\":\"Удмуртская республика\",\"display_name\":\"Udmurtiya Republic, Russia\",\"geoname_code\":\"80\",\"country\":1},\"city\":{\"id\":513,\"name\":\"Izhevsk\",\"name_ascii\":\"Izhevsk\",\"slug\":\"izhevsk\",\"geoname_id\":554840,\"alternate_names\":\"Ижевск\",\"display_name\":\"Izhevsk, Udmurtiya Republic, Russia\",\"search_names\":\"izhevskrespublikaudmurtiiarossiiskaiafederatsiia izhevskrespublikaudmurtiiarussia izhevskrossiiskaiafederatsiia izhevskrussia izhevskudmurtiyarepublicrossiiskaiafederatsiia izhevskudmurtiyarepublicrussia izhevskudmurtskaiarespublikarossiiskaiafederatsiia izhevskudmurtskaiarespublikarussia\",\"latitude\":\"56.84976\",\"longitude\":\"53.20448\",\"population\":631038,\"feature_code\":\"PPLA\",\"timezone\":\"Europe/Samara\",\"subregion\":null,\"region\":6,\"country\":1}}"
            // city: "Ижевск"
            // country: "Российская Федерация"
            // e_mail: "123"
            // house: "123"
            // region: "Удмуртская республика"
            // site_company: "123"
            // staff_users: []
            // stocks: []
            // street: "123"
            // telephone_number: "123"
            // title: "123"

            // city: "ф"
            // country: "ф"
            // email: "ф"
            // house: "ф"
            // new_parts: "Б/У"
            // phone: "ф"
            // region: "ф"
            // site: "ф"
            // street: "ф"
            // title: "ф"
            // verification: "Пройдена"

            let address

            if (city && region && country) {
                address = {
                    country,
                    region,
                    city
                }
            }else {
                address = {}
            }

            const formatData = {
                address: JSON.stringify(address),
                city: values.city ? values.city : '',
                country: values.country ? values.country : '',
                e_mail: values.email ? values.email : '',
                house: values.house ? values.house : '',
                region: values.region ? values.region : '',
                site_company: values.site ? values.site : '',
                street: values.street ? values.street : '',
                telephone_number: values.phone ? values.phone : '',
                title: values.title ? values.title : '',
                staff_users: [],
                stocks: [],
                verification: values.verification == "Пройдена" ? true : false,
                new_parts: values.new_parts == "Б/У" ? false : true,
            }

            addCompany(formatData)
            setVisibleAdd(false)
            getCompanies(currentPage)

        }).catch(() => message.error('Непридвиденная ошибка'))

    }


    return (
        <div>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item><Link to={'/'}>Главная</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Поставщики</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                <h1>База поставщиков</h1>
                <Button style={{margin: '0 0 15px'}} type="primary" onClick={() => {
                    AddForm.validateFields()
                    setVisibleAdd(true)
                }}>
                    <PlusOutlined/> Добавить поставщика
                </Button>
                <Drawer
                    title="Добавить поставщика"
                    width={720}
                    onClose={() => setVisibleAdd(false)}
                    visible={visibleAdd}
                    bodyStyle={{paddingBottom: 80}}
                    footer={
                        <div
                            style={{
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={() => {
                                AddForm.resetFields()
                                setVisibleAdd(false)
                            }} style={{marginRight: 8}}>
                                Закрыть
                            </Button>
                            <Button disabled={disableSaveBtn} type="primary"
                                    htmlType="submit" onClick={() => handleAddCompany()}>
                                Добавить
                            </Button>
                        </div>
                    }
                >
                    <Form layout="vertical"
                          form={AddForm}
                          onChange={() => {
                              AddForm.validateFields()
                                  .then(() => {
                                      setDisableSaveBtn(false)
                                  })
                                  .catch(() => {
                                      setDisableSaveBtn(true)
                                  })
                          }}
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="title"
                                    label="Название компании"
                                    rules={[{required: true, message: 'Пожалуйста, введите название компании'}]}
                                >
                                    <Input name="title"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="verification"
                                    label="Верификация"
                                    rules={[{required: true, message: 'Пожалуйста, выберите значение'}]}
                                >
                                    <Select
                                        placeholder="Верификация"
                                        optionFilterProp="children"
                                    >
                                        <Option value="Пройдена">Пройдена</Option>
                                        <Option value="Не пройдена">Не пройдена</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="new_parts"
                                    label="Продавец запчастей"
                                    rules={[{required: true, message: 'Пожалуйста, выберите значение'}]}
                                >
                                    <Select
                                        placeholder="Продавец запчастей"
                                        optionFilterProp="children"
                                    >
                                        <Option value="Б/У">Б/У</Option>
                                        <Option value="Новые">Новые</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item label={'Тип заполнения адреса'}>
                                    <Space align="center">
                                        Каталог адресов РФ: <Switch checked={typeAddress} onChange={() => setTypeAddress(!typeAddress)}/>
                                    </Space>
                                </Form.Item>
                            </Col>
                            {
                                typeAddress ? (
                                    <>
                                        <Col span={12}>
                                            <Form.Item
                                                name="country"
                                                label="Страна"
                                            >
                                                <Select showSearch
                                                        filterOption={(input, option) =>
                                                            option?.children && option?.children instanceof String ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                                        }
                                                        placeholder="Выберите страну"
                                                        onChange={(value, data: any) => {
                                                            getRegions(data)
                                                            setCountry({id: data['data-id'], alternate_names: data.value})
                                                            EditForm.setFieldsValue({'country': value, 'address': `${value},`})
                                                        }}>
                                                    {countries.length > 0 ? renderSelectOptions(countries) : null}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name="region"
                                                label="Регион"
                                            >
                                                <Select showSearch
                                                        filterOption={(input, option) =>
                                                            option?.children && option?.children instanceof String ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                                        }
                                                        placeholder="Выберите регион"
                                                        onChange={(value, data: any) => {
                                                            getCities(data)
                                                            setRegion({id: data['data-id'], alternate_names: data.value})
                                                            EditForm.setFieldsValue({
                                                                'region': value,
                                                                'address': `${EditForm.getFieldValue('country')}, ${value}`
                                                            })
                                                        }}>
                                                    {regions.length > 0 ? renderSelectOptions(regions) : null}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name="city"
                                                label="Город"

                                            >
                                                <Select showSearch
                                                        onChange={(value, data: any) => {
                                                            setCity({id: data['data-id'], alternate_names: data.value})
                                                            EditForm.setFieldsValue({
                                                                'city': value,
                                                                'address': `${EditForm.getFieldValue('country')}, ${EditForm.getFieldValue('region')}, ${value}`
                                                            })
                                                        }
                                                        }
                                                        filterOption={(input, option) =>
                                                            option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        placeholder="Выберите город">
                                                    {cities.length > 0 ? renderSelectOptions(cities) : null}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </>
                                ) : (
                                    <>
                                        <Col span={12}>
                                            <Form.Item
                                                name="country"
                                                label="Страна"
                                            >
                                                <Input name={'country'}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name="region"
                                                label="Регион"
                                            >
                                                <Input name={'region'}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name="city"
                                                label="Город"

                                            >
                                                <Input name={'city'}/>
                                            </Form.Item>
                                        </Col>
                                    </>
                                )
                            }

                            <Col span={12}>
                                <Form.Item
                                    name={'street'}
                                    label={'Улица'}
                                >
                                    <Input name="street"/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={'house'}
                                    label={'Дом'}
                                >
                                    <Input name="house"/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={'phone'}
                                    label={'Телефон'}
                                >
                                    <Input name="phone"/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={'email'}
                                    label={'Email'}
                                >
                                    <Input name="email"/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={'site'}
                                    label={'Сайт'}
                                >
                                    <Input name="site"/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Drawer>
                <Table showHeader scroll={{x: 1750, y: 600}} columns={columns} dataSource={companies}
                       loading={loadingCompanies} pagination={false}/>
                <Pagination style={{marginTop: '10px'}} pageSize={24} current={currentPage} onChange={(page) => {
                    setCurrentPage(page)
                    getCompanies(page)
                }} total={pageSize}/>

                <Modal
                    title={`Редактирование информации для ${currentEditPartner?.title}`}
                    centered
                    visible={visiblePartnerModal}
                    onOk={() => handleChangeInfoPartner()}
                    onCancel={() => {
                        EditForm.resetFields()
                        setVisiblePartnerModal(false)
                    }}
                    okText="Редактировать"
                    cancelText="Закрыть"
                >
                    <Form layout="vertical"
                                 form={EditForm}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="title"
                                label="Название компании">
                                <Input name="title"/>
                            </Form.Item>
                        </Col>
                        {/*<Col span={24}>*/}
                        {/*    <Form.Item*/}
                        {/*        name={'address'}*/}
                        {/*        label={'Адрес поставщика'}*/}
                        {/*    >*/}
                        {/*        <Input disabled name="address"/>*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="verification"
                                label="Верификация"
                            >
                                <Select
                                    placeholder="Верификация"
                                    optionFilterProp="children"
                                >
                                    <Option value="Пройдена">Пройдена</Option>
                                    <Option value="Не пройдена">Не пройдена</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="new_parts"
                                label="Продавец запчастей"
                            >
                                <Select
                                    placeholder="Продавец запчастей"
                                    optionFilterProp="children"
                                >
                                    <Option value="Б/У">Б/У</Option>
                                    <Option value="Новые">Новые</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label={'Тип заполнения адреса'}>
                                <Space align="center">
                                    Каталог адресов РФ: <Switch checked={typeAddress} onChange={() => setTypeAddress(!typeAddress)}/>
                                </Space>
                            </Form.Item>
                        </Col>
                        {
                            typeAddress ? (
                                <>
                                    <Col span={12}>
                                        <Form.Item
                                            name="country"
                                            label="Страна"
                                        >
                                            <Select showSearch
                                                    filterOption={(input, option) =>
                                                        option?.children && option?.children instanceof String ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                                    }
                                                    placeholder="Выберите страну"
                                                    onChange={(value, data: any) => {
                                                        getRegions(data)
                                                        setCountry({id: data['data-id'], alternate_names: data.value})
                                                        EditForm.setFieldsValue({'country': value, 'address': `${value},`})
                                                    }}>
                                                {countries.length > 0 ? renderSelectOptions(countries) : null}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="region"
                                            label="Регион"
                                        >
                                            <Select showSearch
                                                    filterOption={(input, option) =>
                                                        option?.children && option?.children instanceof String ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                                    }
                                                    placeholder="Выберите регион"
                                                    onChange={(value, data: any) => {
                                                        getCities(data)
                                                        setRegion({id: data['data-id'], alternate_names: data.value})
                                                        EditForm.setFieldsValue({
                                                            'region': value,
                                                            'address': `${EditForm.getFieldValue('country')}, ${value}`
                                                        })
                                                    }}>
                                                {regions.length > 0 ? renderSelectOptions(regions) : null}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="city"
                                            label="Город"

                                        >
                                            <Select showSearch
                                                    onChange={(value, data: any) => {
                                                        setCity({id: data['data-id'], alternate_names: data.value})
                                                        EditForm.setFieldsValue({
                                                            'city': value,
                                                            'address': `${EditForm.getFieldValue('country')}, ${EditForm.getFieldValue('region')}, ${value}`
                                                        })
                                                    }
                                                    }
                                                    filterOption={(input, option) =>
                                                        option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    placeholder="Выберите город">
                                                {cities.length > 0 ? renderSelectOptions(cities) : null}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </>
                            ) : (
                                <>
                                    <Col span={12}>
                                        <Form.Item
                                            name="country"
                                            label="Страна"
                                        >
                                            <Input name={'country'}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="region"
                                            label="Регион"
                                        >
                                            <Input name={'region'}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="city"
                                            label="Город"

                                        >
                                            <Input name={'city'}/>
                                        </Form.Item>
                                    </Col>
                                </>
                            )
                        }

                        <Col span={12}>
                            <Form.Item
                                name={'street'}
                                label={'Улица'}
                            >
                                <Input name="street"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={'house'}
                                label={'Дом'}
                            >
                                <Input name="house"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={'phone'}
                                label={'Телефон'}
                            >
                                <Input name="phone"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={'email'}
                                label={'Email'}
                            >
                                <Input name="email"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={'site'}
                                label={'Сайт'}
                            >
                                <Input name="site"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={'Файл выгрузки'}
                                name={'file'}
                            >
                                {
                                    EditForm.getFieldValue('file') ? (
                                        <Button type="primary" onClick={() => {
                                            const link = document.createElement('a');
                                            link.href = EditForm.getFieldValue('file');
                                            document.body.appendChild(link);
                                            link.click();
                                            document.body.removeChild(link);
                                        }}>
                                            <ArrowDownOutlined/> Скачать
                                        </Button>
                                    ) : (
                                        <Button disabled type="primary">
                                            <ArrowDownOutlined/> Не предоставлен
                                        </Button>
                                    )
                                }

                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                </Modal>

            </div>

        </div>
    );
};

export default Partners;