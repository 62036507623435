import React, {useEffect, useRef, useState} from 'react';
import {Breadcrumb, Empty, message, Select, Spin} from "antd";
import {Link} from "react-router-dom";
import {restApi} from "../utils/variables";
import {CompanyInfo} from "../components/ListCompanies";
import DetailsCompanyTable from "../components/DetailsCompany/DetailsCompanyTable";

const {Option} = Select

export interface DetailInterface {
    alt_category?: string
    alt_model?: string
    alt_title?: string
    attribute?: string
    brand: string
    category?: string
    company: number
    date_add: string
    date_of_sale?: string
    description?: string
    id: number
    in_order: boolean
    location?: string
    model: string
    oem?: string
    price: number
    price_in: number
    seller: number
    sells?: boolean
    shop_code_number?: string
    stock: number
    title: string
    up_date?: string
    urls?: string
}


export interface FormatDetailForTable {
    key: number | string,
    id: number,
    status: boolean,
    title: string,
    brand: string,
    model: string,
    sells: boolean,
    category: string | null,
    oem: string | null,
    shop_code_number: string,
    price_in: number,
    price: number,
    company: number,
    description?: string,
    urls?: string
}

export function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}


const Details = () => {
    const controller = new AbortController()
    const [selectedCompany, setSelectedCompany] = useState(undefined)
    const prevSelectedCompany = usePrevious(selectedCompany)
    const [details, setDetails] = useState<FormatDetailForTable[]>([])
    const [loadingDetails, setLoadingDetails] = useState(false)
    const [companies, setCompanies] = useState<CompanyInfo[]>([])
    const [loadingCompanies, setLoadingCompanies] = useState(true)

    useEffect(() => {
        getCompanies()
    }, [])

    useEffect(() => {
        if (selectedCompany) {
            getDetailsByCompany(selectedCompany)
        }

        if (selectedCompany && (prevSelectedCompany !== selectedCompany)) {
            return () => { controller.abort() }
        }
    }, [selectedCompany])

    const getDetailsByCompany = async (id: number | undefined) => {
        setLoadingDetails(true)
        try {
            const url = `${restApi}/accounting_rest/details_find/?company=${id}`
            const res = await fetch(url, {
                signal: controller.signal,
                headers: {
                    "Authorization": `Token ${localStorage.getItem('token')}`,
                    "Content-Type": "application/json"
                }
            })
            const json = await res.json()
            setLoadingDetails(false)
            if (json.count > 0) {
                let formatDetailsForTable
                formatDetailsForTable = json.results.map((detail: DetailInterface, idx: number) => {
                    return {
                        key: idx,
                        id: detail.id,
                        status: detail.sells,
                        title: detail.title,
                        brand: detail.brand ? detail.brand : '-',
                        model: detail.model ? detail.model : '-',
                        category: detail.category ? detail.category : '-',
                        oem: detail.oem ? detail.oem : '-',
                        shop_code_number: detail.shop_code_number,
                        price_in: detail.price_in,
                        price: detail.price,
                        description: detail.description,
                        sells: detail.sells,
                        company: detail.company,
                        urls: detail.urls
                    }
                })
                setDetails(formatDetailsForTable)
            } else {
                setLoadingDetails(false)
                setDetails([])
            }
        } catch (e: any) {
            if (e?.name === 'AbortError') {
                return
            }
            message.error('Ошибка загрузки деталей компании')
            setLoadingDetails(false)
            setDetails([])
            console.log('error fetching company details', e)
        }
    }

    const getCompanies = async () => {
        try {
            const url = `${restApi}/accounting_rest/company_by_user/`
            const res = await fetch(url, {
                method: "GET",
                headers: {'Authorization': `Token ${localStorage.getItem('token')}`}
            })
            const json = await res.json()

            setCompanies(json.results)
            setLoadingCompanies(false)
        } catch (e) {
            console.log('get companies error', e)
            message.error('Ошибка получения компаний');
            setLoadingCompanies(false)
            setCompanies([])
        }
    }

    const handleChangeSelect = (value: any) => {
        setSelectedCompany(value)
    }

    return (
        <div>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item><Link to={'/'}>Главная</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to={'/company/edit'}>Редактирование компании</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Каталог запчастей</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                <p>Выберите компанию</p>

                {
                    !loadingCompanies && companies.length > 0 ? (
                        <Select
                            showSearch
                            style={{width: 200}}
                            placeholder="Компании"
                            optionFilterProp="children"
                            onChange={handleChangeSelect}
                            filterOption={(input: any, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                companies.map(company => <Option key={company.id} value={company.id}>{company.title}</Option>)
                            }
                        </Select>
                    ) : loadingCompanies ? (<div className="spin-container"><Spin size="large"/></div>) :  <Empty style={{margin:"20px 0"}} description={<><b>У вас нет доступных компаний, сначала нужно</b> <Link to={'/company/edit'}>создать компанию</Link> </>}/>
                }

                {
                    <div style={{marginTop: '10px'}}>
                        <DetailsCompanyTable getDetailsByCompany={getDetailsByCompany} details={details} loadingDetails={loadingDetails}/>
                    </div>
                    // <Empty description={'Возможно вы не выбрали компанию или в выбранной компании нет выгруженных деталей'}/>
                }


            </div>
        </div>
    );
};

export default Details;