import { Tabs } from "antd";
import AnalyticsAnon from "../components/Analytics/AnalyticsAnon";
import AnalyticsAuth from "../components/Analytics/AnalyticsAuth";

export default function Analytics() {
  return (
    <div
      className="site-layout-background"
      style={{ padding: 24, minHeight: 360 }}
    >
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Анонимные" key="1">
          <AnalyticsAnon />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Авторизованные" key="2">
          <AnalyticsAuth />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
