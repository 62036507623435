import React from 'react';
import {Form, Input, Modal} from "antd";
import {profileInfoInterface} from "../../../pages/ProfilePage";

interface ComponentProps {
    isModalEditVisible: boolean,
    setIsModalEditVisible: (arg: boolean) => void,
    profileInfo: profileInfoInterface
}

const EditProfileInfoModal = ({isModalEditVisible, setIsModalEditVisible, profileInfo}: ComponentProps) => {


    return (
        <Modal title="Редактирование основной информации профиля"
               visible={isModalEditVisible}
               onOk={() => setIsModalEditVisible(false)}
               onCancel={() => setIsModalEditVisible(false)}
               okText="Редактировать"
               cancelText="Закрыть"
        >
            <Form name="complex-form" labelCol={{span: 8}} wrapperCol={{span: 16}}>
                <Form.Item label={'Логин'}>
                    <Form.Item
                        name="username"
                        noStyle
                        rules={[{required: true, message: 'Пожайлуста введите логин'}]}
                    >
                        <Input style={{width: 160, marginBottom: '5px'}} name={'username'}
                               placeholder="Введите ваш логин"
                               defaultValue={profileInfo ? profileInfo.username : ''}/>
                    </Form.Item>
                </Form.Item>

                <Form.Item label={'Email'}>
                    <Form.Item
                        name="email"
                        noStyle
                        rules={[{required: true, message: 'Пожайлуста введите email'}]}
                    >
                        <Input style={{width: 160, marginBottom: '5px'}} name={'email'}
                               placeholder="Введите ваш email" defaultValue={profileInfo ? profileInfo.email : ''}/>
                    </Form.Item>
                </Form.Item>
                <Form.Item label={'Имя'}>
                    <Form.Item
                        name="firstName"
                        noStyle
                        // rules={[{required: true, message: 'Пожайлуста введите имя'}]}
                    >
                        <Input style={{width: 160, marginBottom: '5px'}} name={'firstName'}
                               placeholder="Введите ваше имя"
                               defaultValue={profileInfo ? profileInfo.first_name : ''}/>
                    </Form.Item>
                </Form.Item>
                <Form.Item label={'Фамиллия'}>
                    <Form.Item
                        name="lastName"
                        noStyle
                        // rules={[{required: true, message: 'Пожайлуста введите фамиллию'}]}
                    >
                        <Input style={{width: 160}} name={'lastName'} placeholder="Введите вашу фамиллию"
                               defaultValue={profileInfo ? profileInfo.last_name : ''}/>
                    </Form.Item>
                </Form.Item>


            </Form>
        </Modal>
    );
};

export default EditProfileInfoModal;