import React from 'react'
import {makeAutoObservable} from "mobx";

class Store {
    user = {}

    currentChat = null

    chatPage = {
        title: 'Диалоги',
        show: false,
        messages: [],
        dialogs: []
    }

    ordersPage = {
        title: 'Обработка заказов',
        key: 'chat',
        show: false,
        orders: []
    }

    disputOrdersPage = {
        title: 'Спорные заказы',
        show: false,
        key: 'disput',
        disputs: []
    }

    partnersPage = {
        title: 'База поставщиков',
        key: 'partners',
        show: false,
    }

    tabs = [
        {...this.chatPage},
        {...this.ordersPage},
        {...this.disputOrdersPage},
        {...this.partnersPage},
    ]

    constructor() {
        makeAutoObservable(this)
    }

    setCurrentChat(data: any) {
        this.currentChat = data
    }

    setStateToChatPage(state: any) {
        this.chatPage = {
            ...this.chatPage,
            ...state
        }
    }

}

export default new Store