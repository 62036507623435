import React, {FC, useState} from 'react';
import {Link} from 'react-router-dom'
import {Layout, Menu, Badge} from 'antd';
import {
    ShopOutlined,
    UserOutlined,
    EditOutlined,
    WechatOutlined,
    QuestionCircleOutlined,
    CarryOutOutlined,
    DatabaseOutlined,
    DeliveredProcedureOutlined,
    BarChartOutlined,

} from '@ant-design/icons';
import './header.css';

const {Sider} = Layout;
const {SubMenu} = Menu;

const HeaderComponent: FC = () => {
    const [collapsed, setCollapsed] = useState(false)

    const onCollapsed = (collapsed: any) => {
        setCollapsed(collapsed)
    }

    return (
        <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onCollapse={onCollapsed}
        >
            <div className={`logo ${collapsed ? 'collapsed' : ''}`}>
                <Link to={'/'}>Partobo-admin</Link>
            </div>

            <Menu theme="dark" mode="inline">
                <Menu.Item key="1" icon={<UserOutlined/>}><Link to={'/'}>Статистика</Link></Menu.Item>
                {/* <Menu.Item icon={<WechatOutlined/>} key="2">
                    <Link to={'/chats'}>Чаты</Link>
                    <Badge count={0} offset={[10, 0]}></Badge>
                </Menu.Item> */}

                <SubMenu key="sub1" icon={<ShopOutlined/>} title={<span>Заказы</span>}>
                    <Menu.Item icon={<CarryOutOutlined/>} key="3">
                        <Link to={'/orders'}>Обработка заказов</Link>
                    </Menu.Item>
                    <Menu.Item icon={<QuestionCircleOutlined/>} key="5">
                        <Link to={'/disputes'}>Спорные заказы</Link>
                    </Menu.Item>
                </SubMenu>
                <Menu.Item icon={<DeliveredProcedureOutlined/>} key="6">
                    <Link to={'/partners'}>База поставщиков</Link>
                </Menu.Item>
                <Menu.Item icon={<DatabaseOutlined/>} key="7">
                    <Link to={'/transport_companies'}>Список ТК</Link>
                </Menu.Item>
                <SubMenu key="sub2" icon={<EditOutlined/>} title={<span>Каталоги</span>}>
                    <Menu.Item icon={<EditOutlined />} key="8">
                        <Link to={'/catalog'}>Каталог запчастей</Link>
                    </Menu.Item>
                    <Menu.Item icon={<EditOutlined />} key="9">
                        <Link to={'/catalog/models'}>Каталог марок-моделей</Link>
                    </Menu.Item>
                </SubMenu>
                <Menu.Item icon={<BarChartOutlined />} key="10">
                    <Link to={'/analytics'}>Аналитика</Link>
                </Menu.Item>
            </Menu>
        </Sider>
    );
};

export default HeaderComponent;