import React, {useEffect, useState} from 'react';
import {Breadcrumb, Empty, message, Select, Spin, Table, Tag, Tooltip, Image} from "antd";
import {Link} from "react-router-dom";
import {CompanyInfo} from "../components/ListCompanies";
import {restApi} from "../utils/variables";
import {usePrevious} from "./Details";

interface DisputInterface {
    "id": number
    "detail_id": number
    "customer_id": number
    "description": string
    "order_id": number
    "date": string
    "is_active": boolean
    "photo": string[]
}

const {Option} = Select

export const currDateLocalString = (date: string)  => {
    const d = new Date(date)
    const months = 'января, февраля, марта, апреля, мая, июня, июля, августа, сентября, октрября, ноября, декабря'.split(',')
    const currDate = d.getDate()
    const currMonth = months[d.getMonth()]
    const currYear = d.getFullYear()
    const currTime = `${d.getHours()}:${d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()}`

    return `${currDate} ${currMonth} ${currYear} ${currTime}`
}


const Disputes = () => {
    const controller = new AbortController()
    const [disputes, setDisputes] = useState<DisputInterface[]>([])
    const [companies, setCompanies] = useState<CompanyInfo[]>([])
    const [selectedCompany, setSelectedCompany] = useState<undefined | number | string>(undefined)
    const prevSelectedCompany = usePrevious(selectedCompany)
    const [loadingCompanies, setLoadingCompanies] = useState(false)
    const [loadingDisputes, setLoadingDisputes] = useState(false)


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Статус',
            dataIndex: 'is_active',
            key: 'is_active',
            render: (active: boolean) => active ? <Tag color={'green'}>Активный</Tag> :
                <Tag color={'volcano'}>Закрыт</Tag>
        },
        {
            title: 'Дата спора',
            dataIndex: 'date',
            key: 'date',
            width: 200,
            render: (date: string) => currDateLocalString(date)
        },
        {
            title: 'ID Покупателя',
            dataIndex: 'customer_id',
            key: 'customer_id',
        },
        {
            title: 'ID зазказа',
            dataIndex: 'order_id',
            key: 'order_id',
        },
        {
            title: 'ID детали',
            dataIndex: 'detail_id',
            key: 'detail_id',
        },

        {
            title: 'Причина спора',
            dataIndex: 'description',
            key: 'description',
            ellipsis: {
                showTitle: false
            },
            render: (text: string) => text ? <Tooltip placement="topLeft" title={text}>{text}</Tooltip> : '-'

        },

        {
            title: 'Изображение',
            dataIndex: 'photo',
            key: 'photo',
            ellipsis: {
                showTitle: false
            },
            render: (photo: string[]) => photo.length > 0 ? <Image width={100} height={70} src={`${restApi}/uploads/${photo[0]}`}/> : '-'
        }
    ];


    useEffect(() => {
        const getCompanies = async () => {
            try {
                const url = `${restApi}/updater/companies/?page=1&new_parts=0`
                const res = await fetch(url, {
                    method: "GET",
                    headers: {'Authorization': `Token ${localStorage.getItem('token')}`}
                })
                const json = await res.json()

                setCompanies(json.results)
                setLoadingCompanies(false)
            } catch (e) {
                console.log('get companies error', e)
                message.error('Ошибка получения компаний');
                setLoadingCompanies(false)
                setCompanies([])
            }
        }

        getCompanies()
    }, [])


    useEffect(() => {
        if (selectedCompany) {
            getDisputes(selectedCompany)
        }

        if (selectedCompany && (prevSelectedCompany !== selectedCompany)) {
            return () => {
                controller.abort()
            }
        }
    }, [selectedCompany])

    const getDisputes = async (companyID: string | number) => {
        setLoadingDisputes(true)
        try {
            const url = `${restApi}/accounting_rest/disputs/?company=${companyID}`
            const res = await fetch(url, {
                signal: controller.signal,
                headers: {
                    'Authorization': `Token ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            })
            const json = await res.json()
            setDisputes(json)
            setLoadingDisputes(false)
        } catch (e) {
            setLoadingDisputes(false)
            console.log('error fetch disputes', e)
            message.error('Ошибка загрузки спорных заказов')
        }
    }

    const handleChangeSelect = (value: any) => {
        setSelectedCompany(value)
    }

    return (
        <div>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item><Link to={'/'}>Главная</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Спорные заказы</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                <h1>Спорные заказы</h1>
                {
                    !loadingCompanies && companies.length > 0 ? (
                        <div style={{marginBottom: '10px'}}>
                            <Select
                                showSearch
                                style={{width: 200}}
                                placeholder="Компании"
                                optionFilterProp="children"
                                onChange={handleChangeSelect}
                                filterOption={(input: any, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    companies.map((company: CompanyInfo) => <Option key={company.id}
                                                                                    value={company.id}>{company.title}</Option>)
                                }
                            </Select>
                        </div>

                    ) : loadingCompanies ? (
                            <div className="spin-container"><Spin size="large"/></div>
                        ) :
                        <Empty style={{margin:"20px 0"}} description={<><b>У вас нет доступных компаний, сначала нужно</b> <Link to={'/company/edit'}>создать компанию</Link> </>}/>
                }

                <Table columns={columns} dataSource={disputes} loading={loadingDisputes}/>
            </div>

        </div>
    );
};

export default Disputes;