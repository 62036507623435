import React, {useEffect, useState} from 'react';
import {Table, Tag, Space, Button, Tooltip, Modal, Form, Col, Input, Row, Upload, Typography, message} from 'antd';
import {EditOutlined, DeleteOutlined, DollarCircleOutlined} from '@ant-design/icons'
import axios from "axios";
import {restApi} from "../../utils/variables";

export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const {TextArea} = Input;
const {Text} = Typography;

const DetailsCompanyTable = ({details, loadingDetails, getDetailsByCompany}) => {
    const [visibleEditModal, setVisibleEditModal] = useState(false)
    const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
    const [currentEditData, setCurrentEditData] = useState(undefined)
    const [fileList, setFileList] = useState([])
    const [previewImage, setPreviewImage] = useState()
    const [previewVisible, setPreviewVisible] = useState(false)
    const [previewTitle, setPreviewTitle] = useState('')
    const [form] = Form.useForm()

    useEffect(() => {
        if (currentEditData && currentEditData.urls) {
            formatImagesForUploader(currentEditData.urls)
        } else {
            formatImagesForUploader()
        }
    }, [currentEditData])

    const handleEditDetail = (detail) => {
        setCurrentEditData(detail)
        form.setFieldsValue({
            title: detail?.title,
            category: detail?.category,
            brand: detail?.brand,
            model: detail?.model,
            shop_code_number: detail?.shop_code_number,
            description: detail?.description,
            price_in: detail?.price_in,
            price: detail?.price,
            oem: detail?.oem,
            urls: formatFileListToString(fileList)

        })
        setVisibleEditModal(true)
    }

    const formatFileListToString = (fileList) => {
        if (fileList && fileList.length > 0) {
            return fileList.map(item => item.url).join(',')
        } else {
            return ''
        }
    }

    const handleDeleteDetail = (detail) => {
        setVisibleDeleteModal(true)
        setCurrentEditData(detail)
    }

    const sellDetail = async (id, companyId) => {
        try {
            const url = `${restApi}/updater/details/${id}/`
            await fetch(url, {
                method: "PATCH",
                headers: {
                    "Authorization": `Token ${localStorage.getItem('token')}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({sells: true})
            })
            getDetailsByCompany(companyId)
            message.success('Деталь снята с продажи!')
        } catch (e) {
            console.log('error sell detail', e)
            message.error('Ошибка снятия с продажи')
        }
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            render: (tags) => (
                <>
                    {
                        tags ? <Tag color={'volcano'}>Продано</Tag> : <Tag color={'green'}>В наличии</Tag>
                    }
                </>
            ),
        },
        {
            title: 'Категория',
            dataIndex: 'category',
            key: 'category',
            ellipsis: {
                showTitle: false
            },
            render: text => <Tooltip placement="topLeft" title={text}>{text}</Tooltip>
        },
        {
            title: 'Наименование',
            dataIndex: 'title',
            key: 'title',
            ellipsis: {
                showTitle: false
            },
            render: text => <Tooltip placement="topLeft" title={text}>{text}</Tooltip>

        },

        {
            title: 'Марка',
            dataIndex: 'brand',
            key: 'brand',
            ellipsis: {
                showTitle: false
            },
            render: text => <Tooltip placement="topLeft" title={text}>{text}</Tooltip>
        },
        {
            title: 'Модель',
            dataIndex: 'model',
            key: 'model',
            ellipsis: {
                showTitle: false
            },
            render: text => <Tooltip placement="topLeft" title={text}>{text}</Tooltip>
        },
        {
            title: 'OEM',
            dataIndex: 'oem',
            key: 'oem',
            ellipsis: {
                showTitle: false
            },
            render: text => <Tooltip placement="topLeft" title={text}>{text}</Tooltip>
        },
        {
            title: 'Артикул',
            dataIndex: 'shop_code_number',
            key: 'shop_code_number'
        },
        {
            title: 'Цена покупки',
            dataIndex: 'price_in',
            key: 'price_in'
        },
        {
            title: 'Цена продажи',
            dataIndex: 'price',
            key: 'price'
        },
        {
            title: 'Действия',
            key: 'action',
            width: 200,
            render: (detail) => (
                <Space size="middle">
                    <Tooltip title="Снять с продажи">
                        <Button disabled={detail.sells} shape="circle"
                                onClick={() => sellDetail(detail.id, detail.company)} icon={<DollarCircleOutlined/>}/>
                    </Tooltip>
                    <Tooltip title="Редактировать">
                        <Button shape="circle" icon={<EditOutlined/>} onClick={() => handleEditDetail(detail)}/>
                    </Tooltip>
                    <Tooltip title="Удалить">
                        <Button shape="circle" icon={<DeleteOutlined/>} onClick={() => handleDeleteDetail(detail)}/>
                    </Tooltip>

                </Space>
            ),
        },
    ];

    const uploadImage = async (options) => {
        const {onSuccess, onError, file} = options;

        const fmData = new FormData();
        const config = {
            headers: {
                "Authorization": `Token ${localStorage.getItem('token')}`,
                "Content-Type": "multipart/form-data",
            }
        };
        fmData.append("photo", file);
        try {
            axios.post(
                "https://partobo.ru:8000/accounting_rest/images/",
                fmData,
                config
            ).then((res) => {
                let newFileList = []
                const newFile = {
                    name: res.data.photo.substring(res.data.photo.lastIndexOf('/') + 1),
                    status: 'done',
                    url: `${restApi}${res.data.photo}`
                }

                newFileList = [...fileList, newFile]
                newFileList = newFileList.map((item, idx) => {
                    return {uid: idx, ...item}
                })
                setFileList(newFileList)
                onSuccess("Ok");
            })
        } catch (err) {
            console.log("Error: ", err);
            onError({err});
        }
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview)
        setPreviewVisible(true)
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))

    };

    const formatImagesForUploader = (urls) => {
        let formatUrls

        if (urls) {
            formatUrls = urls.split(',').filter(item => item !== '').map((item, idx) => {
                return {
                    uid: idx,
                    name: `${item.substring(item.lastIndexOf('/') + 1)}`,
                    status: 'done',
                    url: item,
                }
            })
        } else {
            formatUrls = []
        }
        setFileList(formatUrls)
    }

    const updateDetail = async (values, detail) => {
        setVisibleEditModal(false)

        try {
            const url = `${restApi}/updater/details/${detail.id}/`
            await fetch(url, {
                method: "PATCH",
                headers: {
                    "Authorization": `Token ${localStorage.getItem('token')}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(values)
            })

            getDetailsByCompany(detail.company)
            message.success('Редактирование прошло успешно')
        } catch (e) {
            console.log('detail edit error', e)
            message.error('Ошибка редактирования детали')
        }

    }

    const deleteDetail = async (id) => {
        setVisibleDeleteModal(false)

        try {
            const url = `${restApi}/updater/details/${id}/`
            await fetch(url, {
                method: "DELETE",
                headers: {
                    "Authorization": `Token ${localStorage.getItem('token')}`,
                    "Content-Type": "application/json"
                }
            })
            message.success('Деталь успешно удалена')
            getDetailsByCompany(currentEditData.company)
        } catch (e) {
            console.log('error delete detail', e)
            message.error('Ошибка удаления детали')
        }
    }

    const handleModalFormSubmit = () => {
        form.validateFields()
            .then(async (values) => {
                const editableValues = {
                    ...values,
                    urls: formatFileListToString(fileList)
                }
                await updateDetail(editableValues, currentEditData)
            })
            .catch((errorInfo) => {
            })
    }

    const removeFile = (file) => {
        if (fileList.length > 0) {
            const newFileList = fileList.filter(item => item.uid !== file.uid)
            setFileList(newFileList)
        }
    }


    return (
        <>
            <Table columns={columns} dataSource={details} loading={loadingDetails}/>
            <Modal
                title={`Редактирование детали ID ${currentEditData?.id}`}
                centered
                visible={visibleEditModal}
                onOk={() => handleModalFormSubmit()}
                onCancel={() => {
                    form.resetFields()
                    setVisibleEditModal(false)
                }}
                okText="Редактировать"
                cancelText="Закрыть"
            >
                <Form layout="vertical"
                      form={form}>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="title"
                                label="Наименование детали"
                            >
                                <Input name="title"
                                       disabled={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="category"
                                label="Категория"
                            >
                                <Input name="category"
                                       disabled={true}/>
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="brand"
                                label="Марка"
                            >
                                <Input name="brand"
                                       disabled={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="model"
                                label="Модель"
                            >
                                <Input name="model"
                                       disabled={true}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="shop_code_number"
                                label="Артикул"
                                rules={[{required: true, message: 'Пожайлуста введите артикул'}]}
                            >
                                <Input name="shop_code_number"
                                       placeholder="Артикул детали"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="oem"
                                label="OEM"
                            >
                                <Input name="oem"
                                       placeholder="Артикул детали"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="price_in"
                                label="Цена комплекта"
                                rules={[{required: true, message: 'Пожайлуста введите цену входа'}]}
                            >
                                <Input name="price_in"
                                       placeholder="Цена комплекта"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="price"
                                label="Цена детали"
                                rules={[{required: true, message: 'Пожайлуста введите цену детали'}]}
                            >
                                <Input name="price_in"
                                       placeholder="Цена детали"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="description"
                                label="Описание"
                            >
                                <TextArea rows={4}
                                          name="description"
                                          placeholder="Описание детали"/>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={16}>
                        <div style={{margin: '0px 10px'}}>
                            <div style={{marginBottom: '5px'}}>
                                <Text type="warning">Загрузка изображений (до 5 шт.)</Text>
                            </div>
                            <Upload
                                action="https://partobo.ru:8000/accounting_rest/images/"
                                listType="picture-card"
                                customRequest={uploadImage}
                                fileList={fileList}
                                onPreview={handlePreview}
                                onRemove={removeFile}
                                // onChange={this.handleChange}
                            >
                                {fileList.length >= 5 ? null : 'Загрузить'}
                            </Upload>
                        </div>
                        <Modal
                            visible={previewVisible}
                            title={previewTitle}
                            footer={null}
                            onCancel={() => setPreviewVisible(false)}
                        >
                            <img alt="example" style={{width: '100%'}} src={previewImage}/>
                        </Modal>
                    </Row>
                </Form>
            </Modal>
            <Modal
                title={`Удаление детали ${currentEditData?.id}`}
                centered
                visible={visibleDeleteModal}
                onOk={() => deleteDetail(currentEditData.id)}
                onCancel={() => setVisibleDeleteModal(false)}
                okText="Удалить"
                cancelText="Закрыть"
            >
                <p>Вы действительно хотите удалить деталь {currentEditData?.title}?</p>
            </Modal>
        </>
    );
};

export default DetailsCompanyTable;