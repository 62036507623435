import React, {FC, useEffect, useState} from 'react';
import {
    Breadcrumb,
    Empty,
    message,
    Pagination,
    Select,
    Spin,
    Switch,
    Table,
    Tag,
    Space, Tooltip, Button, Modal, Form, Row, Col, Input, Drawer
} from "antd";
import {Link} from "react-router-dom";
import {EditOutlined, RollbackOutlined} from '@ant-design/icons';
import {CompanyInfo} from "../components/ListCompanies";
import {restApi} from "../utils/variables";
import {usePrevious} from "./Details";

const {Option} = Select

const CatalogModelEdit: FC = () => {
    const controller = new AbortController()
    const [companies, setCompanies] = useState<CompanyInfo[]>([])
    const [loadingCompanies, setLoadingCompanies] = useState(false)
    const [loadingAltTitles, setLoadingAltTitles] = useState(false)
    const [showBlank, setShowBlank] = useState(false)
    const [selectedCompany, setSelectedCompany] = useState<undefined | number | string>(undefined)
    const [altTitles, setAltTitles] = useState([])
    const [blankTitles, setBlankTitles] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(1)
    const prevSelectedCompany = usePrevious(selectedCompany)
    const [EditForm] = Form.useForm()
    const [currentEditModel, setCurrentEditModel] = useState<any>(undefined)
    const [visibleModal, setVisibleModal] = useState(false)
    const [brands, setBrands] = useState<any[]>([])
    const [modyfys, setModyfys] = useState<any[]>([])
    const [models, setModels] = useState<any[]>([])
    const [disableBtnEdit, setDisableBtnEdit] = useState(true)

    useEffect(() => {
        const getCompanies = async () => {
            setLoadingCompanies(true)
            try {
                const url = `${restApi}/updater/companies/?page=1&new_parts=0`
                const res = await fetch(url, {
                    method: "GET",
                    headers: {'Authorization': `Token ${localStorage.getItem('token')}`}
                })
                const json = await res.json()

                setCompanies(json.results)
                setLoadingCompanies(false)
            } catch (e) {
                console.log('get companies error', e)
                message.error('Ошибка получения компаний');
                setLoadingCompanies(false)
                setCompanies([])
            }
        }

        const getBrands = async () => {
            try {
                const url = `${restApi}/updater/brands/`
                const res = await fetch(url, {
                    headers: {
                        'Authorization': `Token ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    }
                })
                const json = await res.json()
                setBrands(json.results)
            } catch (e) {
                setBrands([])
            }
        }

        getCompanies()
        getBrands()
    }, [])

    useEffect(() => {
        if (selectedCompany) {
            getCatalogModels(selectedCompany)
        }

        if (selectedCompany && (prevSelectedCompany !== selectedCompany)) {
            return () => {
                controller.abort()
            }
        }
    }, [selectedCompany, showBlank])

    const getCatalogModels = async (company: number | string, page = 1) => {
        setLoadingAltTitles(true)
        try {
            let url
            showBlank ?
                url = `${restApi}/updater/brand_model/?company_id=${company}&page=${page}&show_blank_items=true` :
                url = `${restApi}/updater/brand_model/?company_id=${company}&page=${page}`

            const res = await fetch(url, {
                signal: controller.signal,
                headers: {
                    'Authorization': `Token ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            })
            const json = await res.json()
            setAltTitles(json.results)
            setBlankTitles(json.blank_items)
            setPageSize(json.count)
            setLoadingAltTitles(false)
        } catch (e) {
            setLoadingAltTitles(false)
            console.log('fetch details catalog error', e)
            message.error('Ошибка загрузки каталога деталей')
        }
    }

    const getModelsByMark = async (id: any) => {
        setModels([])
        setModyfys([])
        EditForm.setFieldsValue({modify_catalog: '', model_catalog: ''})
        if (id) {
            try {
                const url = `${restApi}/updater/models/?brand=${id}`
                const res = await fetch(url, {
                    headers: {
                        'Authorization': `Token ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    }
                })
                const json = await res.json()
                setModels(json.results)
            } catch (e) {
                message.error('Ошибка получения моделей по марке')
                setModyfys([])
                setModels([])
                EditForm.setFieldsValue({modify_catalog: '', model_catalog: ''})
            }
        } else {
            EditForm.setFieldsValue({modify_catalog: '', model_catalog: ''})
            setModels([])
            setModyfys([])
        }

    }

    const getModifysByModelId = async (id: any) => {
        setModyfys([])
        EditForm.setFieldsValue({modify_catalog: ''})
        if (id) {
            try {
                const url = `${restApi}/updater/modifys/?model=${id}`
                const res = await fetch(url, {
                    headers: {
                        'Authorization': `Token ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    }
                })
                const json = await res.json()
                setModyfys(json.results)
            } catch (e) {
                message.error('Ошибка получения модификаций по модели')
                setModyfys([])
                EditForm.setFieldsValue({modify_catalog: ''})
            }
        } else {
            setModyfys([])
            EditForm.setFieldsValue({modify_catalog: ''})
        }

    }

    const handleChangeSelect = (value: any) => {
        setSelectedCompany(value)
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Марка',
            dataIndex: 'original_brand',
            key: 'original_brand',
        },
        {
            title: 'Модель',
            dataIndex: 'original_model',
            key: 'original_model',
        },

        {
            title: 'Поколение',
            dataIndex: 'original_years',
            key: 'original_years',
        },
        {
            title: 'Модель для каталога',
            dataIndex: 'alt_brand_model',
            key: 'alt_brand_model',
            width: 200,
            render: (alt_title: any) => alt_title && alt_title.title ?
                <Tag color="green">{alt_title.title}</Tag> : <Tag color="volcano">Не указано</Tag>

        },
        {
            title: 'Модификация для каталога',
            dataIndex: 'alt_model_modify',
            key: 'alt_model_modify',
            width: 200,
            render: (alt_title: any) => alt_title && alt_title.title ? <Tag color="green">{alt_title.title}</Tag> :
                <Tag color="volcano">Не указано</Tag>

        },
        {
            title: 'Действия',
            key: 'action',
            width: 100,
            render: (data: any) => (
                <Space size="middle">
                    <Tooltip title="Редактировать">
                        <Button shape="circle" icon={<EditOutlined/>} onClick={() => handleEditModelCatalog(data)}/>
                    </Tooltip>
                    <Tooltip title="Снять категорию">
                        <Button shape="circle" icon={<RollbackOutlined/>} onClick={() => handleDisableModel(data)}/>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const handleEditModelCatalog = (data: any) => {
        setCurrentEditModel(data)
        setVisibleModal(true)
        EditForm.setFieldsValue({modify_catalog: '', model_catalog: '', brand_catalog: ''})
        setModels([])
        setModyfys([])
    }

    const saveBrandModelChange = async (id: any, body: any) => {
        try {
            const url = `${restApi}/updater/brand_model/${id}/`
            await fetch(url, {
                method: 'PUT',
                headers: {
                    'Authorization': `Token ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })

            message.success('Успешно отредактировано')
            setVisibleModal(false)
            if (selectedCompany) {
                getCatalogModels(selectedCompany, currentPage)
            }
        } catch (e) {
            message.error('Ошибка редактирования')
            console.log(e)
        }
    }

    const handleDisableModel = async (data: any) => {
        if (data && data.id) {
            const body = {
                ...data,
                alt_model_modify: null,
                alt_brand_model: null,
            }
            await saveBrandModelChange(data.id, body)
        }else {
            message.error('Непредвиденная ошибка')
        }
    }

    const editModelForCatalog = () => {
        EditForm.validateFields().then((values) => {
            let body

            if (values.modify_catalog) {
                body = {
                    ...currentEditModel,
                    alt_model_modify: values.modify_catalog
                }
            }else {
                body = {
                    ...currentEditModel,
                    alt_brand_model: values.model_catalog
                }
            }

            if (currentEditModel && currentEditModel.id) {
                saveBrandModelChange(currentEditModel.id, body)
            }else {
                message.error('Непредвиденная ошибка')
            }
        })
    }


    return (
        <div>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item><Link to={'/'}>Главная</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Марки-модели</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                <h1>Каталог марок-моделей</h1>
                {
                    !loadingCompanies && companies.length > 0 ? (
                        <div style={{marginBottom: '10px'}}>
                            <Select
                                showSearch
                                style={{width: 200}}
                                placeholder="Компании"
                                optionFilterProp="children"
                                onChange={handleChangeSelect}
                                filterOption={(input: any, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    companies.map((company: CompanyInfo) => <Option key={company.id}
                                                                                    value={company.id}>{company.title}</Option>)
                                }
                            </Select>
                        </div>

                    ) : loadingCompanies ? (
                            <div className="spin-container"><Spin size="large"/></div>
                        ) :
                        <Empty style={{margin: "20px 0"}}
                               description={<><b>У вас нет доступных компаний, сначала нужно</b> <Link
                                   to={'/company/edit'}>создать компанию</Link> </>}/>
                }
                {
                    blankTitles > 0 ? (
                        <div style={{display: 'flex', alignItems: 'center', margin: '20px 0'}}>
                            <Tag color="volcano"><b>{blankTitles} шт.</b> для заполнения</Tag>
                            <Space align="center">
                                Незаполненные поля: <Switch checked={showBlank}
                                                            onChange={() => setShowBlank(!showBlank)}/>
                            </Space>
                        </div>
                    ) : null
                }
                <Table showHeader scroll={{x: 800, y: 600}} columns={columns} dataSource={altTitles}
                       loading={loadingAltTitles} pagination={false}/>
                {
                    selectedCompany ? (
                        <Pagination style={{marginTop: '10px'}} pageSize={5} current={currentPage} onChange={(page) => {
                            setCurrentPage(page)
                            getCatalogModels(selectedCompany, page)
                        }} total={pageSize}/>
                    ) : null
                }
                <Modal
                    title={`${currentEditModel?.original_brand} ${currentEditModel?.original_model} ${currentEditModel?.original_years}`}
                    centered
                    visible={visibleModal}
                    onCancel={() => {
                        EditForm.resetFields()
                        setVisibleModal(false)
                    }}
                    okText="Редактировать"
                    cancelText="Закрыть"
                    footer={
                        <div
                            style={{
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={() => setVisibleModal(false)} style={{marginRight: 8}}>
                                Закрыть
                            </Button>
                            <Button onClick={editModelForCatalog} type="primary"
                                    htmlType="submit">
                                Редактировать
                            </Button>
                        </div>
                    }
                >
                    <Form layout="vertical"
                          form={EditForm}
                    >
                        <Row gutter={16}>
                            <Col span={24}>

                                <Form.Item
                                    name="brand_catalog"
                                    label="Марка для каталога">
                                    <Select
                                        showSearch
                                        onChange={(value) => getModelsByMark(value)}
                                        placeholder="Марка для каталога"
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            brands && brands.length > 0 ?
                                                brands.map((brand: any) => <Option key={brand.id} value={brand.id}>{brand.title}</Option>) :
                                                <Option value={'Загрузка'}>Загрузка...</Option>
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="model_catalog"
                                    label="Модель для каталога">
                                    <Select
                                        showSearch
                                        onChange={(value) => getModifysByModelId(value)}
                                        placeholder="Модель для каталога"
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            models && models.length > 0 ?
                                                models.map((brand: any) => <Option key={brand.id} value={brand.id}>{brand.title}</Option>) :
                                                <Option value={'Загрузка'}>Загрузка...</Option>
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="modify_catalog"
                                    label="Модификация для каталога">
                                    <Select
                                        showSearch
                                        disabled={modyfys.length == 0}
                                        placeholder="Модификация для каталога"
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            modyfys && modyfys.length > 0 ?
                                                modyfys.map((brand: any) => <Option key={brand.id} value={brand.id}>{brand.title}</Option>) :
                                                <Option value={'Загрузка'}>Загрузка...</Option>
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>

        </div>
    );
};

export default CatalogModelEdit;