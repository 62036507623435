import React, {FC, useEffect, useState} from 'react';
import {
    Breadcrumb,
    Empty,
    message,
    Pagination,
    Select,
    Spin,
    Switch,
    Table,
    Tag,
    Space, Tooltip, Button, Modal, Form, Row, Col, Input
} from "antd";
import {Link} from "react-router-dom";
import {EditOutlined, RollbackOutlined} from '@ant-design/icons';
import {CompanyInfo} from "../components/ListCompanies";
import {restApi} from "../utils/variables";
import {currDateLocalString} from "./Disputes";
import {usePrevious} from "./Details";

const {Option} = Select

const CatalogEdit: FC = () => {
    const controller = new AbortController()
    const [companies, setCompanies] = useState<CompanyInfo[]>([])
    const [loadingCompanies, setLoadingCompanies] = useState(false)
    const [loadingAltTitles, setLoadingAltTitles] = useState(false)
    const [showBlank, setShowBlank] = useState(false)
    const [visibleModal, setVisibleModal] = useState(false)
    const [selectedCompany, setSelectedCompany] = useState<undefined | number | string>(undefined)
    const [altTitles, setAltTitles] = useState([])
    const [blankTitles, setBlankTitles] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(1)
    const prevSelectedCompany = usePrevious(selectedCompany)
    const [currentEditDetail, setCurrentEditDetail] = useState<any>(undefined)
    const [categories, setCategories] = useState<any[]>([])
    const [details, setDetails] = useState<any[]>([])
    const [EditForm] = Form.useForm()

    useEffect(() => {
        const getCompanies = async () => {
            setLoadingCompanies(true)
            try {
                const url = `${restApi}/updater/companies/?page=1&new_parts=0`
                const res = await fetch(url, {
                    method: "GET",
                    headers: {'Authorization': `Token ${localStorage.getItem('token')}`}
                })
                const json = await res.json()

                setCompanies(json.results)
                setLoadingCompanies(false)
            } catch (e) {
                console.log('get companies error', e)
                message.error('Ошибка получения компаний');
                setLoadingCompanies(false)
                setCompanies([])
            }
        }

        getCompanies()
        getListCategory()
    }, [])

    useEffect(() => {
        if (selectedCompany) {
            getCatalogDetails(selectedCompany)
        }

        if (selectedCompany && (prevSelectedCompany !== selectedCompany)) {
            return () => {
                controller.abort()
            }
        }
    }, [selectedCompany, showBlank])

    const getCatalogDetails = async (company: number | string, page = 1) => {
        setLoadingAltTitles(true)
        try {
            let url
            showBlank ?
                url = `${restApi}/updater/alt_title/?company_id=${company}&page=${page}&show_blank_items=true` :
                url = `${restApi}/updater/alt_title/?company_id=${company}&page=${page}`

            const res = await fetch(url, {
                signal: controller.signal,
                headers: {
                    'Authorization': `Token ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            })
            const json = await res.json()
            setAltTitles(json.results)
            setBlankTitles(json.blank_items)
            setPageSize(json.count)
            setLoadingAltTitles(false)
        } catch (e) {
            setLoadingAltTitles(false)
            console.log('fetch details catalog error', e)
            message.error('Ошибка загрузки каталога деталей')
        }
    }

    const getListCategory = async () => {
        try {
            const url = `${restApi}/updater/parts_category/`
            const res = await fetch(url, {
                headers: {
                    'Authorization': `Token ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            })
            const json = await res.json()
            console.log(json)
            setCategories(json.results)
        }catch (e) {
            console.log('fetch list details error', e)
            message.error('Ошибка загрузки деталей по категории')
        }
    }

    const getListDetailsById = async (id: any) => {
        try {
            const url = `${restApi}/updater/parts_by_category/?category=${id}`
            const res = await fetch(url, {
                headers: {
                    'Authorization': `Token ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            })
            const json = await res.json()
            console.log(json)
            setDetails(json.results)
        }catch (e) {
            console.log('fetch list details error', e)
            message.error('Ошибка загрузки деталей по категории')
        }
    }

    const handleChangeSelect = (value: any) => {
        setSelectedCompany(value)
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 100,
        },
        {
            title: 'Текущее название',
            dataIndex: 'original_title',
            key: 'original_title',
            width: 200,
        },
        {
            title: 'Категория для каталога',
            dataIndex: 'alt_title',
            key: 'alt_title_category',
            width: 200,
            render: (alt_title: any) => alt_title && alt_title.category ?
                <Tag color="green">{alt_title.category.title}</Tag> : <Tag color="volcano">Не указано</Tag>

        },
        {
            title: 'Название для каталога',
            dataIndex: 'alt_title',
            key: 'alt_title_detail',
            width: 200,
            render: (alt_title: any) => alt_title && alt_title.title ? <Tag color="green">{alt_title.title}</Tag> :
                <Tag color="volcano">Не указано</Tag>

        },
        {
            title: 'Действия',
            key: 'action',
            width: 100,
            render: (detail: any) => (
                <Space size="middle">
                    <Tooltip title="Редактировать">
                        <Button shape="circle" icon={<EditOutlined/>} onClick={() => handleEditDetailCatalog(detail)}/>
                    </Tooltip>
                    <Tooltip title="Снять категорию">
                        <Button shape="circle" icon={<RollbackOutlined />} onClick={() => handleDisableCategory(detail)}/>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const handleEditDetailCatalog = (detail: any) => {
        setCurrentEditDetail(detail)
        if (detail && detail.alt_title) {
            getListDetailsById(detail.alt_title.category.id)
        }
        EditForm.resetFields()
        EditForm.setFieldsValue({
            original_title: detail.original_title ? detail.original_title : '',
            category: detail.alt_title ? detail.alt_title.category.title : '',
            detail: detail.alt_title ? detail.alt_title.title : ''

        })
        setVisibleModal(true)
        console.log(detail)
    }

    const handleDisableCategory = (detail: any) => {
        const formatData = {
            ...detail,
            alt_title: null
        }
        editDetail(formatData, detail.id)
        selectedCompany && getCatalogDetails(selectedCompany, currentPage)
    }

    const editDetailForCatalog = () => {
        EditForm.validateFields().then(values => {
            const formatData = {
                alt_title: values.detail,
                id: currentEditDetail.id,
                original_title: values.original_title
            }

            if (currentEditDetail.id) {
                editDetail(formatData, currentEditDetail.id)
                selectedCompany && getCatalogDetails(selectedCompany, currentPage)
            }else {
                message.error('Непридвиденная ошибка')
            }

            setVisibleModal(false)
            EditForm.resetFields()

        })
    }

    const editDetail = async (data: any, id: any) => {
        try {
            const url = `${restApi}/updater/alt_title/${id}/`
            await fetch(url, {
                method: 'PUT',
                headers: {
                    'Authorization': `Token ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            message.success('Деталь успешно отредактирована')
        }catch (e) {
            console.log('error edit detail category', e)
            message.error('Ошибка редактирования детали')
        }
    }


    return (
        <div>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item><Link to={'/'}>Главная</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Поставщики</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                <h1>Каталог запчастей</h1>
                {
                    !loadingCompanies && companies.length > 0 ? (
                        <div style={{marginBottom: '10px'}}>
                            <Select
                                showSearch
                                style={{width: 200}}
                                placeholder="Компании"
                                optionFilterProp="children"
                                onChange={handleChangeSelect}
                                filterOption={(input: any, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    companies.map((company: CompanyInfo) => <Option key={company.id}
                                                                                    value={company.id}>{company.title}</Option>)
                                }
                            </Select>
                        </div>

                    ) : loadingCompanies ? (
                            <div className="spin-container"><Spin size="large"/></div>
                        ) :
                        <Empty style={{margin: "20px 0"}}
                               description={<><b>У вас нет доступных компаний, сначала нужно</b> <Link
                                   to={'/company/edit'}>создать компанию</Link> </>}/>
                }
                {
                    blankTitles > 0 ? (
                        <div style={{display: 'flex', alignItems: 'center', margin: '20px 0'}}>
                            <Tag color="volcano"><b>{blankTitles} шт.</b> для заполнения</Tag>
                            <Space align="center">
                                Незаполненные поля: <Switch checked={showBlank}
                                                            onChange={() => setShowBlank(!showBlank)}/>
                            </Space>
                        </div>
                    ) : null
                }
                <Table showHeader scroll={{x: 800, y: 600}} columns={columns} dataSource={altTitles} loading={loadingAltTitles} pagination={false}/>
                {
                    selectedCompany ? (
                        <Pagination style={{marginTop: '10px'}} pageSize={5} current={currentPage} onChange={(page) => {
                            setCurrentPage(page)
                            getCatalogDetails(selectedCompany, page)
                        }} total={pageSize}/>
                    ) : null
                }
                <Modal
                    title={`${currentEditDetail?.original_title}`}
                    centered
                    visible={visibleModal}
                    onOk={() => editDetailForCatalog()}
                    onCancel={() => {
                        EditForm.resetFields()
                        setVisibleModal(false)
                    }}
                    okText="Редактировать"
                    cancelText="Закрыть"
                >
                    <Form layout="vertical"
                          form={EditForm}>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="original_title"
                                    label="Оригинальное наименование">
                                    <Input disabled name="original_title"/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="category"
                                    label="Категория"
                                >
                                    <Select
                                        showSearch
                                        filterOption={(input, option) =>
                                            option?.children && option?.children instanceof String ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                        }
                                        placeholder="Категория"
                                        optionFilterProp="children"
                                        onChange={(value, data: any) => {
                                            EditForm.setFieldsValue({detail: ''})
                                            getListDetailsById(value)
                                        }}
                                    >
                                        {
                                            categories.length > 0 ? (
                                                <>
                                                    {
                                                        categories.map((category: any) => {
                                                            return <Option key={category.id} value={category.id}>{category.title}</Option>

                                                        })
                                                    }
                                                </>

                                            ) : null
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="detail"
                                    label="Наименование"

                                >
                                    <Select
                                        showSearch
                                        filterOption={(input, option) =>
                                            option?.children && option?.children instanceof String ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                        }
                                        placeholder="Деталь"
                                        optionFilterProp="children"
                                    >
                                        {
                                            details.length > 0 ? (
                                                <>
                                                    {
                                                        details.map((detail: any) => {
                                                            return <Option key={detail.id} value={detail.id}>{detail.title}</Option>

                                                        })
                                                    }
                                                </>

                                            ) : null
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
                </Modal>
            </div>

        </div>
    );
};

export default CatalogEdit;