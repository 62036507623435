import React, {FC} from 'react';
import {DownOutlined, UpOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import '../styles/card-stat.css'

interface ComponentProps {
    typeFooter: 'link' | 'statUp' | 'statDown',
    text: string,
    count: string,
    cardTitle: string,
    icon: any,
}

const CardStat: FC<ComponentProps> = ({typeFooter, text, count, cardTitle, icon}) => {

    const renderFooter = (typeFooter: 'link' | 'statUp' | 'statDown', text: string) => {
        switch (typeFooter) {
            case "link":
                return (
                    <>
                        <a href="">{text}</a>
                    </>
                )
            case "statUp":
                return (
                    <>
                        <UpOutlined style={{
                            background: 'rgba(76, 175, 80, 0.08)',
                            color:'rgb(76, 175, 80)',
                            padding: '10px',
                            borderRadius: '50%',
                            marginRight: '10px'
                        }} />
                        {text}
                    </>
                )
            case "statDown":
                return (
                    <>
                        <DownOutlined style={{
                            background: 'rgba(244, 67, 54, 0.08)',
                            color:'rgb(244, 67, 54)',
                            padding: '10px',
                            borderRadius: '50%',
                            marginRight: '10px'
                        }} />
                        {text}
                    </>
                )
            default:
                return {text}
        }
    }

    return (
        <div className={'card-stat'}>
            <div className="card-stat__head">
                <div className="card-stat__info">
                    <div className="card-stat__title">{cardTitle ? cardTitle : 'Без заголовка'}</div>
                    <div className="card-stat__count">{count ? count : 0}</div>
                </div>
                <div className="card-stat__chart">
                    {
                        icon ? icon : <QuestionCircleOutlined />
                    }
                </div>
            </div>
            <div className="card-stat__footer">
                {renderFooter(typeFooter, text)}
            </div>
        </div>
    );
};

export default CardStat;