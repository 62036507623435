import { Input, message, Modal, Pagination } from "antd";
import { useEffect, useState } from "react";
import { restApi } from "../../utils/variables";
import AnalyticsAuthUserList from "./AnalyticsAuthUserList";
import AnalyticsUserActions from "./AnalyticsUserActions";

const { Search } = Input;

//analytics/list_users/
type AnalyticsUsersBody = {
  page: number;
  type_user?: "anon" | "user";
  search_keyword?: string;
};

const AnalyticsAuth = () => {
  //table users
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [totalPagesTableUsers, setTotalPagesTableUsers] = useState(1);

  //actions modal
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [openActionsModal, setOpenActionsModal] = useState(false);

  useEffect(() => {
    getUsers({ page: 1, type_user: "user" });
  }, []);

  const getUsers = async (body: AnalyticsUsersBody) => {
    try {
      setLoadingUsers(true);
      const url = `${restApi}/analytics/list_users/`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const json = await response.json();

      if (json.status === "ok") {
        setUsers(json.users);
        setTotalPagesTableUsers(json.pages.total_pages);
      } else {
        throw Error(response.statusText);
      }
    } catch (e: any) {
      message.error(e.message);
    } finally {
      setLoadingUsers(false);
    }
  };

  const handleChangePageUsers = async (page: number) => {
    getUsers({ page, type_user: "user" });
  };

  const handleOpenModal = (user: any) => {
    setSelectedUser(user);
    setOpenActionsModal(true);
  };

  const handleCloseModal = () => {
    setOpenActionsModal(false);
    setSelectedUser(null);
  };

  const onSearch = (value: string) => {
    if (value.length > 0) {
      getUsers({ page: 1, type_user: "user", search_keyword: value });
    } else {
      getUsers({ page: 1, type_user: "user" });
    }
  };

  return (
    <>
      <Search
        placeholder="Поиск по email, имя, фамилия"
        allowClear
        onSearch={onSearch}
        enterButton
        style={{marginBottom: 10}}
      />
      <AnalyticsAuthUserList
        loadingUsers={loadingUsers}
        users={users}
        handleOpenModal={handleOpenModal}
      />
      <Pagination
        style={{ marginTop: 10 }}
        defaultCurrent={1}
        onChange={handleChangePageUsers}
        total={totalPagesTableUsers * 10}
        showSizeChanger={false}
      />

      <Modal
        title={`${
          Boolean(selectedUser?.user_name.replace(" ", ""))
            ? selectedUser?.user_name
            : selectedUser?.user_email
        }`}
        open={openActionsModal}
        onCancel={handleCloseModal}
        width={600}
        footer={[]}
      >
        <AnalyticsUserActions user={selectedUser} />
      </Modal>
    </>
  );
};

export default AnalyticsAuth;
