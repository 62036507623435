import React, { FC, useEffect, useState } from "react";
import {
  Avatar,
  Card,
  Layout,
  Popconfirm,
  Popover,
  Result,
  Skeleton,
  Tooltip,
} from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  SettingOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import HeaderComponent from "./Header/HeaderComponent";
import ProfilePage, { profileInfoInterface } from "../pages/ProfilePage";
import Disputes from "../pages/Disputes";
import Login from "../pages/Login";
import { restApi } from "../utils/variables";
import EditProfileInfoModal from "./modals/profile/EditProfileInfoModal";
import EditProfilePasswordModal from "./modals/profile/EditProfilePasswordModal";
// import ChatPage from "../pages/ChatPage";
import Orders from "../pages/Orders";
import Partners from "../pages/Partners";
import CatalogEdit from "../pages/CatalogEdit";
import CatalogModelEdit from "../pages/CatalogModelEdit";
import { observer } from "mobx-react-lite";
import store from "../mobx/store";
import TransportCompaniesPage from "../pages/TransportCompaniesPage";
import Analytics from "../pages/Analytics";
import Chat from "./Chat";

const { Header, Content } = Layout;
const { Meta } = Card;

const App: FC = observer(() => {
  const [profileInfo, setProfileInfo] = useState<profileInfoInterface>({});
  const [loading, setLoading] = useState(true);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [isModalEditPasswordVisible, setIsModalEditPasswordVisible] =
    useState(false);

  useEffect(() => {
    getProfileInfo();
  }, []);

  const getProfileInfo = async () => {
    try {
      const url = `${restApi}/sp/rest-auth/user/`;
      const res = await fetch(url, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });
      const json = await res.json();
      setProfileInfo(json);
      setLoading(false);
    } catch (e) {
      console.log("Что-то пошло не так...", e);
    }
  };

  const content = () => {
    return (
      <Card
        style={{ width: 300 }}
        actions={[
          <Tooltip placement="bottom" title={"Изменение пароля"}>
            <SettingOutlined
              onClick={() => setIsModalEditPasswordVisible(true)}
              key="setting"
            />
          </Tooltip>,
          <Tooltip placement="bottom" title={"Редактирование"}>
            <EditOutlined
              onClick={() => setIsModalEditVisible(true)}
              key="edit"
            />
          </Tooltip>,
          <Tooltip placement="bottom" title={"Выход"}>
            <Popconfirm
              title="Вы уверены, что хотите выйти?"
              onConfirm={logout}
              // onCancel={cancel}
              okText="Да"
              cancelText="Нет"
            >
              <LogoutOutlined key={"logout"} />
            </Popconfirm>
          </Tooltip>,
        ]}
      >
        <Skeleton loading={loading} avatar active>
          <Meta
            avatar={<Avatar icon={<UserOutlined />} />}
            title={`Логин ${profileInfo.username}`}
            description={`Почта ${profileInfo.email}`}
          />
          <p
            style={{
              marginTop: "20px",
              fontWeight: "bold",
            }}
          >{`ФИО ${
            profileInfo.first_name && profileInfo.last_name
              ? `${profileInfo.first_name} ${profileInfo.last_name}`
              : "Не указано"
          }`}</p>
        </Skeleton>
      </Card>
    );
  };

  const logout = () => {
    localStorage.removeItem("token");
    window.location.href = "/";
  };

  return (
    <Router>
      {localStorage.getItem("token") ? (
        <Layout style={{ minHeight: "100vh" }}>
          <HeaderComponent />
          <Layout className="site-layout">
            <Header className="site-layout-background" style={{ padding: 0 }}>
              <div style={{ float: "right", marginRight: "20px" }}>
                <Popover
                  placement="bottomRight"
                  title={"Профиль"}
                  content={content}
                  trigger="click"
                >
                  <Avatar icon={<UserOutlined />} />
                </Popover>
                <EditProfileInfoModal
                  isModalEditVisible={isModalEditVisible}
                  setIsModalEditVisible={setIsModalEditVisible}
                  profileInfo={profileInfo}
                />

                <EditProfilePasswordModal
                  isModalEditPasswordVisible={isModalEditPasswordVisible}
                  setIsModalEditPasswordVisible={setIsModalEditPasswordVisible}
                />
              </div>
            </Header>
            <Content style={{ margin: "0 16px" }}>
              <Switch>
                <Route path={"/"} exact component={ProfilePage} />
                {/* <Route
                  path={"/chats"}
                  render={() => {
                    return <ChatPage store={store} />;
                  }}
                /> */}
                <Route
                  path={"/orders"}
                  render={() => {
                    return <Orders store={store} />;
                  }}
                />
                <Route path={"/disputes"} component={Disputes} />
                <Route path={"/partners"} component={Partners} />
                <Route path={"/catalog/models"} component={CatalogModelEdit} />
                <Route path={"/catalog"} component={CatalogEdit} />
                <Route
                  path={"/transport_companies"}
                  component={TransportCompaniesPage}
                />
                <Route path={"/analytics"} component={Analytics} />
                <Route path="*">
                  <Result
                    status="404"
                    title="404"
                    subTitle="Упс, похоже такой страницы не существует."
                    extra={<Link to={"/"}>На главную</Link>}
                  />
                </Route>
              </Switch>
            </Content>
          </Layout>
          <Chat />
        </Layout>
      ) : (
        <Switch>
          <Route exact path={"/"}>
            <Login />
          </Route>
          {/*<Route path={'/registration'}>*/}
          {/*    <Registration/>*/}
          {/*</Route>*/}
          <Route path="*">
            <Layout
              style={{
                minHeight: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Result
                status="404"
                title="404"
                subTitle="Упс, похоже такой страницы не существует."
                extra={<Link to={"/"}>На главную</Link>}
              />
            </Layout>
          </Route>
        </Switch>
      )}
    </Router>
  );
});

export default App;
